import { withTranslation } from "react-i18next";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import moment from "moment";
import { Link } from "react-router-dom";
class ContractsViewer extends ResultViewer {

    render() {
        const {t} = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
                {
                    this.state.data.data.map((row, index) => {
                        let signatureMessage = null;
                        switch (row.status) {
                            case "pending":
                                signatureMessage = t("Waiting for send notification email");
                                break;
                            case "sent":
                                let waitingPeople = [];
                                if (!row.signature_date) {
                                    waitingPeople.push(row.employee);
                                }
                                if (!row.contact_person_signature_date) {
                                    waitingPeople.push(row.contact_person);
                                }
                                signatureMessage = t("email_notifications_sent", {waitingPeople: waitingPeople.join("<br>")});
                                if (row.sign_by_date) {
                                    if (!row.signed_by_user === false) {
                                        signatureMessage += "<br/>" + t("You must sign this document until {{date}}", {date: row.sign_by_date});
                                    }
                                }
                                break;
                            case "signed":
                                signatureMessage = t("signed");
                                if (row.signature_date) {
                                    signatureMessage += `<br><b>${row.employee}:</b> ${row.signature_date}`;
                                }
                                if (row.contact_person_signature_date) {
                                    signatureMessage += `<br><b>${row.contact_person}:</b> ${row.contact_person_signature_date}`;
                                }
                                break;
                            default:
                        }
                        return <div className="contracts-card position-relative" key={index}>
                            <div className="contracts-card-header">
                                <div className="contracts-card-icon">
                                    <img src="/assets/images/icons/Contracts-icon.svg" alt="Contract"/>
                                </div>
                                <div className="contracts-card-head">
                                    <Link to={"/documents/contracts/" + row.ID} className="text-light text-decoration-none stretched-link">
                                        <span>{row.title}</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="contracts-card-alt">
                                <div className="contracts-card-date my-2">
                                    <img src={row.contact_person_photo ?? "/assets/images/icons/people-user-icon.svg"}
                                        alt={row.contact_person} className='me-2 rounded-circle' width={"30px"} height={"30px"} />
                                    {t("Contact Person")}:&nbsp; {row.contact_person}
                                </div>
                                <div className="contracts-card-date">
                                    <span className="contracts-card-date-light">{t("Start Date - Expiry Date")}:</span>
                                    <span className="contracts-card-date-bold">
                                        {moment(row.start_date, "DD-MM-YYYY").format("DD.MM.YYYY")}&nbsp;-&nbsp;
                                        {row.expiry_date ? moment(row.expiry_date, "DD-MM-YYYY").format("DD.MM.YYYY") : "-"}
                                    </span>
                                </div>
                                <div className="contracts-card-date">
                                    <span className="contracts-card-date-light">{t("Status")}:</span>
                                    <span className="contracts-card-date-bold">{t(row.status)}</span>
                                </div>
                                <div className="contracts-card-desc">
                                    <span dangerouslySetInnerHTML={{ __html: signatureMessage }}></span>
                                </div>
                            </div>
                        </div>;
                    })
                }
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </>;
        }
    }
}

export default withTranslation("common")(ContractsViewer);