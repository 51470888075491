import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const LoginLayout = () => {
    return (
        <div className="main-body overflow-auto">
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    )
};

export default LoginLayout;