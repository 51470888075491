import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ChatFiles({ message, isReceiver }) {
    const [toggler, setToggler] = useState(false);
    let [slide, setSlide] = useState(1);
    let imageSources = [];
    if (message?.files?.image_files.count > 0) {
        for (let image of message?.files?.image_files.data) {
            imageSources.push(image.file_path);
        }
    }
    return (
        <>
            {
                message?.files?.image_files.count > 0 ? (
                    <>
                        <div className={"d-flex flex-wrap " + (isReceiver ? "justify-content-start" : "justify-content-end")} style={{
                            width: "200px"
                        }}>
                            {
                                message.files.image_files.data.map((image, index) => {
                                    return <div key={index} className="overflow-hidden" style={{ width: "100px", height: "100px" }} role="button" onClick={() => { setSlide(index + 1); setToggler(!toggler) }}>
                                        <img className="img-fluid" src={image.file_path} alt={image.file_name} />
                                    </div>
                                })
                            }
                        </div>
                        <FsLightbox
                            toggler={toggler}
                            sources={imageSources}
                            slide={slide}
                        />
                    </>
                ) : <></>
            }
            {
                message?.files?.doc_files.count > 0 ? (
                    <>
                        <div className={"list-group text-start"} style={{

                        }}>
                            {
                                message.files.doc_files.data.map((file, index) => {
                                    return <a href={file.file_path} key={index} target='_blank' rel="noreferrer" className="fs-6 list-group-item fw-bolder">
                                        <span className={file.file_icon + " me-2"}></span>
                                        {file.file_name}
                                    </a>;
                                })
                            }
                        </div>
                    </>
                ) : <></>
            }
        </>
    );
}

export default ChatFiles;