import PunchControl from "../../components/PunchControl";
import DocumentTitle from "react-document-title";
import { AuthConsumer } from "../../AuthContext";
import { useTranslation } from "react-i18next";

function Welcome() {
  const { t } = useTranslation("common");
  let title = t("Home");
  return (
    <AuthConsumer>
      {({ user }) => (
        <section className="home-bg">
          <div className="home-alt-bg">
            <DocumentTitle title={title}></DocumentTitle>
            <div className="home-logo">
              <img className="img-fluid" src="assets/images/main/logo-white.svg" alt="Logo" />
            </div>
            <div className="home-head">
              <h1>{t("Welcome user", { user: user.name })}</h1>
            </div>
            <PunchControl />
          </div>
        </section>
      )}
    </AuthConsumer>
  );
}

export default Welcome;