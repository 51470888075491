import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SignatureCanvas from 'react-signature-canvas';
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import { useTranslation } from "react-i18next";

function ContractDetail() {
    let [contract, setContract] = useState({
        title: "",
        ID: null,
        start_date: null,
        expiry_date: null,
        contact_person: null,
        status: null,
        sign_by_date: null,
        signature_date: null,
        contact_person_signature_date: null,
        created_at: null,
        last_updated: null,
        file: null,
        employee: null,
        signed_by_user: true
    });
    var sigCanvas = new SignatureCanvas();

    let params = useParams();
    let fetchContract = useCallback(() => {
        let singleApi = process.env.REACT_APP_API_URL + "/documents/contracts?contracts.ID=" + params.id;
        axios.get(singleApi, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            if (data?.data[0]) {
                setContract(data.data[0]);
            }
        }).catch((error) => { });
    }, [params.id]);

    useEffect(() => {
        fetchContract();
    }, [fetchContract]);

    const [t] = useTranslation('common');

    function openSignatureBox() {
        const signatureBox = withReactContent(Swal);
        signatureBox.fire({
            title: t("Sign Contract"),
            html: <>
                <div className="mb-3">{t("Draw your signature in the box below")}:</div>
                <SignatureCanvas penColor='black'
                    canvasProps={{ width: 300, height: 300, className: 'sigCanvas border border-4 border-primary' }}
                    backgroundColor="#FFFFFF"
                    ref={(ref) => { sigCanvas = ref }} />
            </>,
            confirmButtonText: <>
                <i className="fa fa-signature me-2"></i>
                Sign Contract
            </>,
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            customClass: {
                cancelButton: "bg-danger",
                confirmButton: "bg-primary"
            },
            preConfirm: function () {
                Swal.showLoading();
                return axios.post(
                    process.env.REACT_APP_API_URL + "/signContract",
                    {
                        signature: sigCanvas.toDataURL(),
                        contract: params.id
                    },
                    { withCredentials: true }
                ).then((response) => {
                    fetchContract();
                    Swal.hideLoading();
                });
            }
        })
    }

    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={contract.title}>
                    <h1>{contract.title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <PdfViewer pdfUrl={contract.file} />
            {
                contract.signed_by_user === false ? <div className="d-flex position-fixed" style={{
                    zIndex: 2,
                    bottom: "75px",
                    right: "20px"
                }}>
                    <div className="input_widget">
                        <button className="btn btn-success mt-2 me-2 sign-contract" onClick={() => openSignatureBox()}>
                            <span className="fa fa-signature me-2"></span>
                            {t("Sign Contract")}
                        </button>
                    </div>
                </div> : <></>
            }
        </div>
    </section>
}

export default ContractDetail;