import axios from "axios";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import { withTranslation } from "react-i18next";

class NotificationViewer extends ResultViewer {

    readNotification(id) {
        let readApi = process.env.REACT_APP_API_URL + "/markReadAlert";
        axios.post(readApi, { alert: id }, { withCredentials: true })
            .then((response) => {
                this.fetchData();
            });

    }

    render() {
        const {t} = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <div className="messages-card-bg">
                <ul className="messages-card-list">
                    {
                        this.state.data.data.map((row, index) => {
                            return <li key={index} className={(row.is_read ? "" : "bg-secondary")}>
                                <a href={row.url} target="_blank" rel="noreferrer" onClick={() => this.readNotification(row.ID)}>
                                    <div className={"messages-card-div-bg"}>
                                        <div className="messages-card-div-left">
                                            <img src="/assets/images/icons/navbar-notification.svg" alt={t("Messages")} />
                                        </div>
                                        <div className="messages-card-div-right">
                                            <div className="messages-card-head">
                                                {row.title}
                                            </div>
                                            <div className="messages-card-alt">
                                                <span className="light" dangerouslySetInnerHTML={{ __html: row.description }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>;
                        })
                    }
                </ul>
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </div>;
        }
    }
}

export default withTranslation("common")(NotificationViewer);