import axios from "axios";
import { useEffect, useState } from "react";
import CsrfHandler from "./CsrfHandler";
import MultipleFileInputWidget from "../Widgets/MultipleFileInputWidget";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function SendChatFileForm(props) {

    let apiUrl = process.env.REACT_APP_API_URL + "/chat/apiSendFile/" + props.chat.ID;
    let [sendFileMessage, setSendFileMessage] = useState("");
    let [fileUploadKey, setFileUploadKey] = useState("");
    const { t } = useTranslation("common");
    const csrfHandler = new CsrfHandler(apiUrl);

    useEffect(() => {
        let fileKeyApi = process.env.REACT_APP_API_URL + "/chat/getChatFileUploadKey";
        axios.get(fileKeyApi, {
            withCredentials: true
        }).then((response) => {
            setFileUploadKey(response.data.data);
        })
    }, [props.chat]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData(document.getElementById("send-file-form"));
        let csrfToken = csrfHandler.getTokenForRequest();
        for(let csrf in csrfToken){
            formData.append(csrf, csrfToken[csrf]);
        }
        axios.post(apiUrl, formData, { withCredentials: true })
            .then((response) => {
                Swal.close();
                props.onSuccess(response.data.data);
            }).catch((error) => {
                if(error.response.data.messages){
                    Swal.fire({
                        title: t('Error'),
                        text: Object.values(error.response.data.messages[0])
                                .join("<br\\>"),
                        icon: 'error',
                        confirmButtonColor: '#d33',
                        confirmButtonText: t("OK")
                    })
                }
            });;
    }

    return <form onSubmit={(e) => handleSubmit(e)} id="send-file-form">
        <div className="text-start p-2">
            <div className="form-widget">
                <label className="form-label fw-bolder fs-6">
                    {t("Message")}
                </label>
                <textarea className="form-control" name="chat_message[message]" placeholder="Type a message..." onChange={(e) => { setSendFileMessage(e.target.value) }} defaultValue={sendFileMessage}
                ></textarea>
            </div>
            <MultipleFileInputWidget fileUploadKey={fileUploadKey} name="chat_message[chat_message_files]" fieldName="file"/>
            <button type={"submit"} className="btn bg-secondary mt-3 rounded text-light float-end btn-modal-st">
                <span className="fa fa-paper-plane btn-in-icon"></span>
                {t("Send Message")}
            </button>
        </div>
    </form>
}

export default SendChatFileForm;