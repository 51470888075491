import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function MultipleFileInputWidget({ fileUploadKey, name, fieldName }) {
    let [uploadedFiles, setUploadedFiles] = useState([]);
    const { t } = useTranslation("common");

    async function uploadNewFiles(files) {
        for (let file of files) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append("key", fileUploadKey);
            let uploadApi = process.env.REACT_APP_API_URL + "/uploadFile";
            await axios.post(uploadApi, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                withCredentials: true
            }).then((response) => {
                uploadedFiles.push(response.data.data);
                setUploadedFiles([...uploadedFiles]);
            })
        }
    }
    return <div>
        <label className="form-label fw-bolder fs-6">
            {t("Files")}
        </label>
        <div className="table-responsive">
            {
                uploadedFiles.length > 0 ?
                    <table className="table table-striped table-light table-hover position-relative multiple-files-content">
                        <thead>
                            <tr>
                                <th className="ps-2 text-nowrap">
                                    {t("Preview")}
                                </th>
                                <th className="ps-2">
                                    {t("File name")}
                                </th>
                                <th className="ps-2 text-nowrap">
                                    {t("File size")}
                                </th>
                                <th className="ps-2">

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                uploadedFiles.map((uploadedFile, index) => {
                                    return <tr key={uploadedFile.ID}>
                                        <td>
                                            <div className="text-center" style={{ width: "50px" }}>
                                                {
                                                    uploadedFile.is_image ? (
                                                        // eslint-disable-next-line
                                                        <a href="#">
                                                            <img src={process.env.REACT_APP_API_BASE + "files/uploaded/" + uploadedFile.file_path} className="img-thumbnail ms-2" alt={uploadedFile.file_name} />
                                                        </a>
                                                    ) : (
                                                        <span className={uploadedFile.icon_class + " fs-2 ms-2 text-primary"}></span>
                                                    )

                                                }
                                                <input type={"hidden"} name={name + `[${index}][${fieldName}]`} value={uploadedFile.ID} />
                                            </div>
                                        </td>
                                        <td>
                                            {uploadedFile.file_name}
                                        </td>
                                        <td>
                                            {uploadedFile.file_size}
                                        </td>
                                        <td></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table> : <></>
            }
        </div>
        <label className="btn bg-primary text-light btn-modal-st">
            <span className="fa fa-file-upload btn-in-icon"></span>
            {t("Add New File")}
            <input className="d-none" type="file" multiple onChange={(e) => { uploadNewFiles(e.target.files); }} />
        </label>
    </div>;
}
export default MultipleFileInputWidget;