import moment from 'moment';
import Drawer from 'react-modern-drawer';
import { AuthConsumer } from "../AuthContext";
import Swal from "sweetalert2";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function ShiftDetailDrawer({ detailShift, setDetailShift }) {

    const {t} = useTranslation("common");

    function createShiftSwap() {
        axios.get(process.env.REACT_APP_API_URL + "/requests/getAvailableShiftsForSwap", { withCredentials: true })
            .then((response) => {
                console.log(response.data.data);
                if(!response.data.data){
                    Swal.fire(
                        t("Error"),
                        t("There is no shift available to swap for you."),
                        "error"
                    );
                    return;
                }
                let data = response.data.data;
                let html = `<div>
                        <div id='messages'></div>
                        <div class='mb-3'>
                            <p>${t('Please select one of your shifts to swap')}:</p>
                            <select class='form-select' id='requestee_shift'>
                                <option value=''>${t("Please Select")}</option>` +
                                Object.keys(data).map((key) => {
                                    return `<option value='${key}'>${data[key]}</option>`;
                                }).join("") +
                            `</select>
                        </div>
                        <div class='mb-3'>
                        <p>${t("Please provide comment for your request")}</p>
                        <textarea id='comment' class='form-control rounded' style="height: 250px;"></textarea>
                        </div>
                    </div>`;
                Swal.fire({
                    title: t("Create Shift Swap Request"),
                    icon: "info",
                    html: html,
                    confirmButtonText: t("Create Request"),
                    customClass: {
                        confirmButton: "bg-primary"
                    },
                    showCancelButton: true,
                    cancelButtonText: t("Cancel"),
                    showLoaderOnConfirm: true,
                    preConfirm: function () {
                        let htmlContainer = Swal.getHtmlContainer();
                        return axios.post(process.env.REACT_APP_API_URL + `/requests/createShiftSwapRequest?event=${detailShift.id}`, {
                            requestee_shift: htmlContainer.querySelector("#requestee_shift").value,
                            comment: htmlContainer.querySelector("#comment").value,
                        }, { withCredentials: true })
                            .then((response) => {
                                Swal.fire(
                                    t("Success"),
                                    t("Shift swap request saved successfully"),
                                    "success"
                                );
                            })
                            .catch(function (error) {
                                Swal.fire({
                                    title: t('Error'),
                                    text: Object.values(error.response.data.messages[0])
                                            .join("<br\\>"),
                                    icon: 'error',
                                    confirmButtonColor: '#d33',
                                    confirmButtonText: t("OK")
                                })
                            });
                    }
                });
            })
            .catch(function (error) {
            });

    }

    return <AuthConsumer>
        {({ user }) => (
            <Drawer
                open={detailShift !== null}
                direction='right'
                className='w-100 offcanvas offcanvas-end bg-primary text-light'
            >
                {
                    detailShift !== null ? (
                        <>
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasExampleLabel">{detailShift.title}'s Shift</h5>
                                <button type="button" className="btn btn-icon" onClick={() => setDetailShift(null)}>
                                    <span className='fa fa-times text-light fs-3'></span>
                                </button>
                            </div>
                            <div className="offcanvas-body p-0">
                                <div className="d-flex justify-content-around bg-secondary  header__schedule">
                                    <div className="p-2 d-flex align-content-center me-2 border-end border-4 border-primary">
                                        <div className='display-1 fw-bold me-2 m-auto'>{moment(detailShift.start, "YYYY-MM-DD HH:mm:ss").format("DD")}</div>
                                        <div className='fs-4 m-auto'>
                                            {moment(detailShift.start, "YYYY-MM-DD HH:mm:ss").format("ddd")} <br />
                                            {moment(detailShift.start, "YYYY-MM-DD HH:mm:ss").format("MMM")}
                                        </div>

                                    </div>
                                    <div className='flex-fill pt-1 ps-1'>
                                        <div className='fs-4 fw-bold'>
                                            {moment(detailShift.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} -
                                            {moment(detailShift.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
                                        </div>
                                        <div>{detailShift.employee_group_name}</div>
                                        <div>{detailShift.department_name}</div>
                                    </div>
                                </div>
                                <div className='container'>
                                    <div className="text-center w-100 bg-secondary text-light text-nowrap rounded py-3 mt-3">
                                        <div className="text-center fs-3">
                                            {detailShift.punch_start ? detailShift.punch_start : "..:.."}&nbsp;-&nbsp;
                                            {detailShift.punch_end ? detailShift.punch_end : "..:.."} &nbsp;
                                            <span className='fa fa-clock-o'></span>
                                        </div>
                                    </div>
                                    {(
                                        user.id !== detailShift.employee &&
                                        user.employee_department === detailShift.department_id
                                    ) ? (
                                        <button className="text-center w-100 bg-secondary text-light text-nowrap rounded py-3 mt-5">
                                            <div className="text-center fs-3" onClick={() => createShiftSwap()}>
                                                {t("Create Shift Swap Request")} &nbsp;
                                                <span className='fa fa-chevron-right'></span>
                                            </div>
                                        </button>
                                    ) : <></>
                                    }
                                </div>
                            </div>
                        </>
                    ) : <></>
                }
            </Drawer>
        )}
    </AuthConsumer>;
}

export default ShiftDetailDrawer;