import { Component } from "react";
import axios from "axios";
/**
 * Abstract Class ResultViewer.
 *
 * @class ResultViewer
 */
class ResultViewer extends Component {
    constructor(props) {
        super(props);
        if (this.constructor === ResultViewer) {
            throw new Error("Abstract classes can't be instantiated.");
        }
        this.state = {
            data: {
                headers: {},
                data: [],
                search: [],
                pagination: {
                    limit: 100,
                    page: 1,
                    total_count: 0
                }
            },
            page: 1,
            orderBy: "",
            orderDirection: ""
        };
        this.fetchData = this.fetchData.bind(this);
        this.setPage = this.setPage.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(this.props.dataUrl, {
            withCredentials: true,
            params: {
                page: this.state.page,
                orderBy: this.state.orderBy,
                orderDirection: this.state.orderDirection
            }
        }).then((response) => {
            this.setState({
                data: response.data.data
            });
        }).catch((error) => { });
    }

    setPage(page) {
        this.setState({
            page: page
        }, () => {
            this.fetchData();
        });
    }

    setOrderBy(orderBy, orderDirection) {
        this.setState({
            orderBy: orderBy,
            orderDirection: orderDirection
        }, () => {
            this.fetchData();
        });
    }
}

export default ResultViewer;