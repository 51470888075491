import React from 'react';
import axios from 'axios';
const AuthContext = React.createContext();

class AuthProvider extends React.Component {
    state = { user: {
        logged_in: false
    } }
    componentDidMount(){

        window.user = () => {
          return this.state.user;
        }
        window.userLoggedIn = () => {
          return this.state.user.logged_in;
        }
        axios.get(process.env.REACT_APP_API_URL + "/login/getUserDetails", { withCredentials: true })
        .then((response) => {
            this.setState({
                user: response.data.data
            }, () => {
              if(!this.state.user.logged_in && !["/", "/login"].includes(window.location.pathname) ){
                window.location.assign("/login");
              } else if(this.state.user.logged_in) {
                window.registerPWA();
                axios.get(process.env.REACT_APP_API_URL + "/profile/checkRequiredActions", {withCredentials: true})
                .then((response) => {
                  let checks = response.data.data;
                  if(checks.confirm_required_message){
                    let messageUrl = "/news/" + checks.confirm_required_message;
                    if(messageUrl !== window.location.pathname){
                      window.location.assign(messageUrl);
                    }
                  }else if(checks.unsigned_contract){
                    let contractUrl = "/documents/contracts/" + checks.unsigned_contract;
                    if(contractUrl !== window.location.pathname){
                      window.location.assign(contractUrl);
                    }
                  }
                });

              }
            });
        })
        .catch(function (error) {
        });
    }

    render() {
      return (
        <AuthContext.Provider
          value={{ user: this.state.user }}
        >
          {this.props.children}
        </AuthContext.Provider>
      )
    }
  }
  const AuthConsumer = AuthContext.Consumer
  export { AuthProvider, AuthConsumer }