import { useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import RequestForm from "../../components/Forms/RequestForm";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";
function RequestEdit(props) {
    const {t} = useTranslation("common");
    const params = useParams();
    let title = params.id === "add" ? t("Add Holiday Request") : t("Edit Holiday");
    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <RequestForm requestid={params.id} />
        </div>
    </section>;
}

export default RequestEdit;