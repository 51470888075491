import React, { useEffect, useState } from "react";
import axios from "axios";
import { AuthConsumer } from "../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import { Dropdown } from "bootstrap";
import { useTranslation } from "react-i18next";

function Navbar() {

    const navigate = useNavigate();
    const [notificationCount, setNotificationCount] = useState(0);
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const { t, i18n } = useTranslation("common");
    const languages = getLanguages();

    const activeLanguage = getActiveLanguage(i18n);
    function logout(e) {
        e.preventDefault();
        axios.get(process.env.REACT_APP_API_URL + "/login/logout", { withCredentials: true })
            .then((response) => {
                window.location.assign("/");
            })
            .catch(function (error) {
            });
    };
    useEffect(() => {
        let alertCountApi = process.env.REACT_APP_API_URL + "/alerts/count";
        axios.get(alertCountApi, {
            withCredentials: true
        }).then((response) => {
            let count = response.data.data ? response.data.data : 0;
            setNotificationCount(count);
        }).catch((error) => { });
        setSidebarOpened(false);
    }, [navigate]);

    return (
        <AuthConsumer>
            {({ user }) => (
                <>
                    <section className="navbar pe-3">
                        <div className="navbar-left">
                            <div className="navbar-menu">
                                <div className="slider-menu-mobile mobile-display">
                                    <button className={"toggle-menu " + (sidebarOpened ? "active" : "")} onClick={() => {
                                        setSidebarOpened(!sidebarOpened);
                                    }}>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="navbar-right">
                            <div className="navbar-profile">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/assets/images/icons/navbar-user.svg" alt={user.name} />
                                    </button>
                                    <ul className="dropdown-menu" style={{ right: "0px", left: "unset" }}>
                                        <li><Link className="dropdown-item" to="/profile">{t("Profile")}</Link></li>
                                        <li><Link className="dropdown-item" to="/" onClick={(e) => logout(e)}>{t("Logout")}</Link></li>
                                        {languages.map((item) => {
                                            let liStyleProps = {};
                                            let linkStyleProps = {};
                                            if (item === activeLanguage) {
                                                liStyleProps = {
                                                    backgroundColor: "var(--orange)"
                                                };
                                                linkStyleProps = {
                                                    backgroundColor: "var(--orange)",
                                                    color: "white"
                                                };
                                            }
                                            return <li key={item} style={liStyleProps}>
                                                <Link className={"dropdown-item d-flex justify-content-between"} to={"#"} onClick={(e) => {
                                                    i18n.changeLanguage(item);
                                                    localStorage.setItem("language", item);
                                                }} style={linkStyleProps}>
                                                    <span>{t(item)}</span>
                                                    <img src={`/assets/images/icons/${item}.svg`} style={{
                                                        width: "2rem",
                                                        height: "2rems"
                                                    }} alt={t(item)} />
                                                </Link>
                                            </li>;
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <Link to={"/notifications"} className="navbar-notification">
                                <span className="position-absolute badge rounded-pill bg-danger top-0 end-0 mt-2 me-1">{notificationCount}</span>
                                <img src="/assets/images/icons/navbar-notification.svg" alt="Notification" />
                            </Link>
                        </div>
                    </section>
                    <div className="wrapper-bg">
                        <div id="wrapper" className={"wrapper " + (sidebarOpened ? "wrapper-open" : "")}>
                            <div className="wrapper-body">
                                <div className="wrapper-logo">
                                    <img className="img-fluid" src="/assets/images/main/logo-white.svg" alt={process.env.REACT_APP_NAME} />
                                </div>
                                <ul className="wrapper-list">
                                    <li>
                                        <Link to={"/welcome"}>
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Home")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/statistics">
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Statistics")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/requests">
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Holidays")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/documents"}>
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Documents")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/payslips"}>
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Payslips")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/profile"}>
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Profile")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"#"} onClick={(e) => logout(e)}>
                                            <div className="wrapper-menu-bg">
                                                <div className="wrapper-menu-text">
                                                    {t("Logout")}
                                                </div>
                                                <div className="wrapper-menu-circle">
                                                    <img className="img-fluid" src="/assets/images/icons/wrapper-menu-arrow.svg" alt="Arrow" />
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="wrapper-opacity" className={"wrapper-opacity " + (sidebarOpened ? "wrapper-opacity-open" : "")}></div>
                    </div>
                </>
            )}
        </AuthConsumer>
    );
}

function getLanguages() {
    return ["en", "it", "fr", "tr"];
}

function getActiveLanguage(i18n) {
    if (!i18n.language) {
        let language = localStorage.getItem("language");
        if (language && getLanguages().includes(language)) {
            i18n.changeLanguage(language);
        } else {
            var userLanguage = navigator.language || navigator.userLanguage;
            language = userLanguage.split("-")[0];
            if (language && getLanguages().includes(language)) {
                i18n.changeLanguage(language);
            }
        }
    }
    return i18n.language;
}

export default Navbar;