import { Component } from "react";
import moment from "moment";

class DiffLabel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            diffText: ""
        };
    }

    componentDidMount() {
        this.updateDiffText();
        if (!this.props.end && this.props.start !== null) {
            setInterval(() => {
                this.updateDiffText()
            }, 1000);
        }
    }

    updateDiffText() {
        let end = this.props.end ? moment.utc(this.props.end).local() : moment();
        this.setState({
            diffText: this.props.start ?
                moment.utc(end.diff(
                    moment.utc(this.props.start).local()
                )).format("HH:mm:ss") :
                "00:00:00"
        });
    }

    render() {
        return (
            <span className="me-2">{this.state.diffText}</span>
        )
    }
}

export default DiffLabel;