import { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
class BreakControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeBreak: null,
            breaks: null
        };
    }

    componentDidMount() {
        this.getActiveBreak();
        this.getBreaks();
    }

    getActiveBreak() {
        axios.get(process.env.REACT_APP_API_URL + "/getActiveBreak", { withCredentials: true })
            .then((response) => {
                this.setState({
                    activeBreak: response.data.data
                });
            })
            .catch(function (error) {
            });
    }

    getBreaks() {
        axios.get(process.env.REACT_APP_API_URL + "/getBreaks", { withCredentials: true })
            .then((response) => {
                this.setState({
                    breaks: response.data.data
                });
            })
            .catch(function (error) {
            });
    }

    getLocationPermission(callback = null) {
        Swal.showLoading();
        const { t } = this.props;
        var geolocationId = navigator.geolocation.watchPosition(
            function (pos) {
                if (callback) {
                    callback(pos.coords.longitude, pos.coords.latitude);
                    Swal.close();
                    navigator.geolocation.clearWatch(geolocationId);
                }
            },
            function (err) {
                if (err.code === 1) {
                    Swal.fire(
                        t("Warning"),
                        t("Need location permission for this operation"),
                        "warning"
                    );
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
        return geolocationId;
    };

    startBreak() {
        this.getLocationPermission((longitude, latitude) => {
            let formData = new FormData();
            formData.append("longitude", longitude);
            formData.append("latitude", latitude);
            axios.post(process.env.REACT_APP_API_URL + "/startBreak", formData, { withCredentials: true })
                .then((response) => {
                    this.getActiveBreak();
                    this.getBreaks();
                })
        })
    }

    endBreak() {
        const { t } = this.props;
        this.getLocationPermission((longitude, latitude) => {
            let formData = new FormData();
            formData.append("longitude", longitude);
            formData.append("latitude", latitude);
            axios.post(process.env.REACT_APP_API_URL + "/endBreak", formData, { withCredentials: true })
                .then((response) => {
                    this.getActiveBreak();
                    this.getBreaks();
                }).catch((error) => {
                    Swal.fire(
                        t("Warning"),
                        error.response.data,
                        "error"
                    );
                })
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="home-take">
                {
                    !this.state.activeBreak ? (
                        <button onClick={() => this.startBreak()}>
                            <img src="assets/images/icons/home-stop-icon.svg" alt={t("Take a Break")}/><span>{t("Take a Break")}</span>
                        </button>
                    ) : (
                        <button onClick={() => this.endBreak()}>
                            <img src="assets/images/icons/play.svg" alt={t("End Break")}/><span>{t("End Break")}</span>
                        </button>
                    )
                }
                
            </div>
        );
    }
}

export default withTranslation("common")(BreakControl);