import { withTranslation } from "react-i18next";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import moment from "moment";
class NotesViewer extends ResultViewer {

    render() {
        const {t} = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
                <div className="notes-card-bg">
                    {
                        this.state.data.data.map((row, index) => {
                            return <>
                                <div className="notes-card-head">
                                    <div className="notes-card-text">
                                        <span>{row.heading}</span>
                                    </div>
                                    <div className="notes-card-date">
                                        <span>{moment(row.due_date, "DD-MM-YYYY").format("MMM DD")}</span>
                                        <br />
                                        <span>{t("Executed")}: {row.executed ? t("Yes") : t("No")}</span>
                                    </div>
                                </div>
                                <div className="notes-card-alt">
                                    <span>{row.content}</span>
                                </div>
                            </>;
                        })
                    }
                </div>
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </>;
        }
    }
}

export default withTranslation("common")(NotesViewer);