import axios from "axios";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import Chatbox from "../../components/Chatbox";
import { useTranslation } from "react-i18next";

function PeopleDetail() {
    let params = useParams();
    let [user, setUser] = useState({});
    const [t] = useTranslation('common');
    let [title, setTitle] = useState(t("People Detail"));
    useEffect(() => {
        let singleApi = process.env.REACT_APP_API_URL + "/people/single/" + params.username;
        axios.get(singleApi, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            setUser(data);
            setTitle(data.name + " " + (data?.surname ?? ""));
        }).catch((error) => { });
    }, [params.username]);

    return <section className="main-bg mb-0">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <div className="people-detail-card-bg">
                <div className="people-detail-card-img">
                    <img src={user?.profile_photo ? user.profile_photo : "/assets/images/icons/people-user-icon.svg"} className="rounded-circle" alt={title} />
                </div>
                <div className="people-detail-card-text text-truncate text-wrap">
                    <div className="people-detail-card-text-name">
                        {title} <span>{user?.employee_department?.name}</span>
                    </div>
                    <div className="people-detail-card-text-alt">
                        {user.phone}
                    </div>
                    <div className="people-detail-card-text-alt">
                        {user.email}
                    </div>
                </div>
                <div className="people-detail-card-buttons d-flex flex-nowrap">
                    <a href={"tel:" + user.phone} className="people-detail-card-phone" target="_blank" rel="noreferrer">
                        <img src="/assets/images/icons/phone-icon.svg" alt="phone" />
                    </a>
                    <a href={"mailto:" + user.email} className="people-detail-card-mail" target="_blank" rel="noreferrer">
                        <img src="/assets/images/icons/mail-icon.svg" alt="mails" />
                    </a>
                </div>
            </div>
            <Chatbox recipientUser={user} type={"private"}/>
        </div>
    </section>;
}

export default PeopleDetail;