import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import DocumentTitle from "react-document-title";
import PeopleViewer from "../../components/ResultViewer/PeopleViewer";
import DepartmentViewer from "../../components/ResultViewer/DepartmentViewer";
import EmployeeGroupViewer from "../../components/ResultViewer/EmployeeGroupViewer";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

function People() {
  let params = useParams();
  const [t] = useTranslation('common');
  const tabs = {
    employees: {
      title: t("Employees"),
      viewer: <PeopleViewer dataUrl={process.env.REACT_APP_API_URL + "/people/list"} />
    },
    groups: {
      title: t("Groups"),
      viewer: <EmployeeGroupViewer dataUrl={process.env.REACT_APP_API_URL + "/people/employeesGroups"} />
    },
    departments: {
      title: t("Departments"),
      viewer: <DepartmentViewer dataUrl={process.env.REACT_APP_API_URL + "/people/departments"} />
    }
  };

  const [activeTab, setActiveTab] = useState(params.list ?? "employees");

  let title = t("People");
  return <section className="main-bg">
    <div className="main-header-head">
      <div className="main-header-left">
        <BackButton/>
      </div>
      <div className="main-header-text">
        <DocumentTitle title={title}>
          <h1>{title}</h1>
        </DocumentTitle>
      </div>
      <div className="main-header-right"></div>
    </div>
    <div className="main-body mt-3 p-2">
      <div className="people-header-tabs-bg">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          {
            Object.keys(tabs).map((key) => {
              return <li key={key} className="nav-item" onClick={() => setActiveTab(key)} role="presentation">
                <Link to={`/people/${key}`} className={"nav-link text-light" + (key === activeTab ? " active" : "")}>{tabs[key].title}</Link>
              </li>
            })
          }
        </ul>
      </div>
      {tabs[activeTab].viewer}
    </div>
  </section>;
}

export default People;