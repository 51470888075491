import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Footer() {
    let location = useLocation();
    const [messageCount, setMessageCount] = useState(0);
    var [activeElement, setActiveElement] = useState("welcome");
    const { t } = useTranslation("common");

    useEffect(() => {
        setActiveElement(location.pathname.split("/")[1])
    }, [location]);
    useEffect(() => {
        let messageCountApi = process.env.REACT_APP_API_URL + "/news/count";
        axios
            .get(messageCountApi, { withCredentials: true })
            .then((response) => {
                let count = response.data.data
                    ? response.data.data
                    : 0;
                setMessageCount(count);
            })
            .catch((error) => { });

    }, []);
    return <section className="navigation-bar-bg">
        <ul className="navigation-bar-list">
            <li className={"navigation-menu " + (activeElement === "welcome" ? "navigation-active" : "")} >
                <Link
                    to={"/welcome"}
                    onClick={() => {
                        setActiveElement("welcome")
                    }}>
                    <div className="navigation-menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.053" height="29.471" viewBox="0 0 32.053 29.471">
                            <g id="svgviewer-output" transform="translate(-0.499 -1.207)">
                                <path id="Path_67" data-name="Path 67" d="M18.037,1.833a2.137,2.137,0,0,0-3.021,0l-14.2,14.2a1.07,1.07,0,1,0,1.513,1.513l14.2-14.2,14.2,14.2a1.07,1.07,0,0,0,1.513-1.513l-5.03-5.028V3.97A1.068,1.068,0,0,0,26.141,2.9H24A1.068,1.068,0,0,0,22.936,3.97V6.732Z" transform="translate(0)" fill="#00444f" />
                                <path id="Path_68" data-name="Path 68" d="M14.82,3.293l12.82,12.82V25.1a3.205,3.205,0,0,1-3.205,3.205H5.205A3.205,3.205,0,0,1,2,25.1V16.113L14.82,3.293Z" transform="translate(1.706 2.371)" fill="#00444f" />
                            </g>
                        </svg>
                    </div>
                    <div className="navigation-menu-text">
                        <span>{t("Home")}</span>
                    </div>
                </Link>
            </li>
            <li className={"navigation-menu " + (activeElement === "news" ? "navigation-active" : "")} >
                <Link
                    to={"/news"}
                    onClick={() => {
                        setActiveElement("news")
                    }}>
                    <div className="navigation-menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="35.19" height="30.791" viewBox="0 0 35.19 30.791">
                            <g id="svgviewer-output_1_" data-name="svgviewer-output (1)" transform="translate(0 -2)">
                                <path id="Path_69" data-name="Path 69" d="M.11,5.42A4.4,4.4,0,0,1,4.4,2H30.791A4.4,4.4,0,0,1,35.08,5.42L17.595,16.107ZM0,7.932V23.556l12.763-7.825Zm14.87,9.09L.42,25.876A4.4,4.4,0,0,0,4.4,28.392H18.158a9.911,9.911,0,0,1,2.969-10.878l-.807-.495-2.725,1.665L14.87,17.02Zm20.32-9.09v10.94a9.909,9.909,0,0,0-12.02-2.685l-.743-.455Z" fill="#00444f" />
                                <path id="Path_70" data-name="Path 70" d="M24.4,16.7A7.7,7.7,0,1,1,16.7,9a7.7,7.7,0,0,1,7.7,7.7Zm-4.383-3.693a1.1,1.1,0,0,0-1.509.378L15.93,17.672l-1.2-1.2a1.1,1.1,0,0,0-1.557,1.557l1.7,1.7a1.65,1.65,0,0,0,2.582-.317l2.936-4.9a1.1,1.1,0,0,0-.378-1.509Z" transform="translate(10.794 8.396)" fill="#00444f" />
                            </g>
                        </svg>
                    </div>
                    <div className="navigation-menu-text">
                        <span>{t("News")}</span>
                      
                    </div>
                    <span className="position-absolute badge rounded-pill bg-danger navigation-small-number">{messageCount}</span>
                </Link>
            </li>
            <li className={"navigation-menu " + (activeElement === "scheduler" ? "navigation-active" : "")} >
                <Link to={"/scheduler"}
                    onClick={() => {
                        setActiveElement("scheduler")
                    }}>
                    <div className="navigation-menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29.441" height="29.441" viewBox="0 0 29.441 29.441">
                            <path id="svgviewer-output_2_" data-name="svgviewer-output (2)" d="M6.44,0a.92.92,0,0,1,.92.92v.92h14.72V.92a.92.92,0,0,1,1.84,0v.92h1.84a3.68,3.68,0,0,1,3.68,3.68V25.761a3.68,3.68,0,0,1-3.68,3.68H3.68A3.68,3.68,0,0,1,0,25.761V5.52A3.68,3.68,0,0,1,3.68,1.84H5.52V.92A.92.92,0,0,1,6.44,0ZM1.84,7.36v18.4A1.84,1.84,0,0,0,3.68,27.6H25.761a1.84,1.84,0,0,0,1.84-1.84V7.36Z" transform="translate(0 0)" fill="#00444f" />
                        </svg>
                    </div>
                    <div className="navigation-menu-text">
                        <span>{t("Schedule")}</span>
                    </div>
                </Link>
            </li>
            <li className={"navigation-menu " + (activeElement === "people" ? "navigation-active" : "")} >
                <Link to={"/people"}
                    onClick={() => {
                        setActiveElement("people")
                    }}>
                    <div className="navigation-menu-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29.941" height="29.941" viewBox="0 0 29.941 29.941">
                            <g id="_11399990741661014282" data-name="11399990741661014282" transform="translate(0.25 0.25)">
                                <path id="Path_51" data-name="Path 51" d="M29.969,26.72H3.472A1.472,1.472,0,0,1,2,25.248,13.248,13.248,0,0,1,15.248,12h2.944A13.248,13.248,0,0,1,31.441,25.248,1.472,1.472,0,0,1,29.969,26.72ZM5.047,23.776H28.394a10.3,10.3,0,0,0-10.2-8.832H15.248a10.3,10.3,0,0,0-10.2,8.832Z" transform="translate(-2 2.72)" fill="#00444f" stroke="#fff" strokeWidth="0.5" />
                                <path id="Path_52" data-name="Path 52" d="M14.228,15.9a6.948,6.948,0,1,1,6.948-6.948A6.948,6.948,0,0,1,14.228,15.9Zm0-10.952a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z" transform="translate(0.492 -2)" fill="#00444f" stroke="#fff" strokeWidth="0.5" />
                            </g>
                        </svg>
                    </div>
                    <div className="navigation-menu-text">
                        <span>{t("People")}</span>
                    </div>
                </Link>
            </li>
            <li className={"navigation-menu " + (activeElement === "payslips" ? "navigation-active" : "")} >
                <Link to={"/payslips"}
                    onClick={() => {
                        setActiveElement("payslips")
                    }}>
                    <div className="navigation-menu-icon">
                        <svg id="_21-30" data-name="21-30" xmlns="http://www.w3.org/2000/svg" width="23.132" height="29.441" viewBox="0 0 23.132 29.441">
                            <g id="Document">
                                <path id="Path_58" data-name="Path 58" d="M26.592,6.333,23.8,3.54A5.225,5.225,0,0,0,20.081,2H10.257A5.264,5.264,0,0,0,5,7.257V26.183a5.264,5.264,0,0,0,5.257,5.257H22.875a5.264,5.264,0,0,0,5.257-5.257V10.051a5.225,5.225,0,0,0-1.54-3.718ZM25.1,7.82a3.2,3.2,0,0,1,.4.489H22.875a1.051,1.051,0,0,1-1.051-1.051V4.629a3.2,3.2,0,0,1,.489.4Zm-2.23,21.518H10.257A3.154,3.154,0,0,1,7.1,26.183V7.257A3.154,3.154,0,0,1,10.257,4.1H19.72V7.257a3.154,3.154,0,0,0,3.154,3.154h3.154V26.183a3.154,3.154,0,0,1-3.154,3.154Z" transform="translate(-5 -2)" fill="#00444f" />
                                <path id="Path_59" data-name="Path 59" d="M11.051,11.1h4.206a1.051,1.051,0,0,0,0-2.1H11.051a1.051,1.051,0,1,0,0,2.1Z" transform="translate(-4.743 -1.64)" fill="#00444f" />
                                <path id="Path_60" data-name="Path 60" d="M21.566,13H11.051a1.051,1.051,0,0,0,0,2.1H21.566a1.051,1.051,0,0,0,0-2.1Z" transform="translate(-4.743 -1.434)" fill="#00444f" />
                                <path id="Path_61" data-name="Path 61" d="M21.566,17H11.051a1.051,1.051,0,1,0,0,2.1H21.566a1.051,1.051,0,0,0,0-2.1Z" transform="translate(-4.743 -1.228)" fill="#00444f" />
                                <path id="Path_62" data-name="Path 62" d="M19.463,21H11.051a1.051,1.051,0,1,0,0,2.1h8.412a1.051,1.051,0,0,0,0-2.1Z" transform="translate(-4.743 -1.022)" fill="#00444f" />
                            </g>
                        </svg>
                    </div>
                    <div className="navigation-menu-text">
                        <span>{t("Payslips")}</span>
                    </div>
                </Link>
            </li>
        </ul>
    </section>;
}

export default Footer;