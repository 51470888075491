import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import { withTranslation } from "react-i18next";

class DepartmentViewer extends ResultViewer {

    constructor(props) {
        super(props);
        this.state.filterText = "";
    }

    fetchData() {
        axios.get(this.props.dataUrl, {
            withCredentials: true,
            params: {
                page: this.state.page,
                orderBy: this.state.orderBy,
                orderDirection: this.state.orderDirection,
                name: this.state.filterText
            }
        }).then((response) => {
            this.setState({
                data: response.data.data
            });
        }).catch((error) => { });
    }

    render() {
        let user = typeof window.user == "function" ? window.user() : null;
        const { t } = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
                <div className="people-search mt-4">
                    <div className="input-group mb-3">
                        <span className="input-group-text">
                            <img src="/assets/images/icons/serach-icon.svg" alt={t("Search")} />
                        </span>
                        <input type="search" className="form-control form-input" placeholder={t("Search")} onChange={(e) => {
                            this.setState({ filterText: e.target.value }, () => {
                                this.fetchData();
                            });
                        }} />
                    </div>
                </div>
                <div className="people-tabs-body">
                    <div className="people-card-bg">
                        <ul className="people-card-group-list">
                            {
                                this.state.data.data.map((row, index) => {
                                    return <li key={index}>
                                        <Link to={"/people/department/" + row.name}>
                                            {user.employee_department === row.ID ? (
                                                <span className="fa fa-comment-dots fw-bold fs-4 me-2"></span>
                                            ) : <></>}
                                            <span>{row.name}</span>
                                        </Link>
                                    </li>;
                                })
                            }
                        </ul>
                    </div>
                    <Pagination {...this.state.data.pagination}
                        setPage={this.setPage}
                    />
                </div>
            </>;
        }
    }
}

export default withTranslation("common")(DepartmentViewer);