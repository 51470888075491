import DocumentTitle from "react-document-title";
import ContractsViewer from "../../components/ResultViewer/ContractsViewer";
import DocumentsViewer from "../../components/ResultViewer/DocumentsViewer";
import NotesViewer from "../../components/ResultViewer/NotesViewer";
import { useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

function Documents() {
    const [t] = useTranslation('common');
    const tabs = {
        contracts: {
            title: t("Contracts"),
            viewer: <ContractsViewer dataUrl={process.env.REACT_APP_API_URL + "/documents/contracts"} />
        },
        documents: {
            title: t("Documents"),
            viewer: <DocumentsViewer dataUrl={process.env.REACT_APP_API_URL + "/documents/documents"} />
        },
        notes: {
            title: t("Notes"),
            viewer: <NotesViewer dataUrl={process.env.REACT_APP_API_URL + "/documents/notes"} />
        }
    };
    const [activeTab, setActiveTab] = useState("contracts");
    let title = t("Documents");
    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton/>
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <div className="people-header-tabs-bg">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {
                        Object.keys(tabs).map((key) => {
                            return <li key={key} className="nav-item" onClick={() => setActiveTab(key)} role="presentation">
                                <Link to={"/documents/"} className={"nav-link text-light" + (key === activeTab ? " active" : "")}>{tabs[key].title}</Link>
                            </li>
                        })
                    }
                </ul>
            </div>
            {tabs[activeTab].viewer}
        </div>
    </section>;
}

export default Documents;