import DocumentTitle from "react-document-title";
import { AuthConsumer } from "../../AuthContext";
import ProfileForm from "../../components/Forms/ProfileForm";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";

function Profile() {
    const [t] = useTranslation('common');
    let title = t("Profile");
    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <AuthConsumer>
                {({ user }) => (
                    <ProfileForm user={user} />
                )}
            </AuthConsumer>
        </div>
    </section>;
}

export default Profile;