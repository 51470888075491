import { withTranslation } from "react-i18next";
import ResultViewer from "./ResultViewer";
import moment from "moment";

class RequestsViewer extends ResultViewer {
    render() {
        const {t} = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
            {
                this.state.data.data.map((row, index) => {
                    return <div class="contracts-card" key={index} role="button" onClick={() => this.props.editCallback(row.edit_actions)}>
                    <div class="contracts-card-header">
                        <div class="contracts-card-icon">
                            <img src="/assets/images/icons/holidays-icon.png" alt={t("Holidays")}/>
                        </div>
                        <div class="contracts-card-head">
                            <span>{
                                    moment(row.start_date, "DD-MM-YYYY").format("DD MMMM")
                                } - {
                                    moment(row.end_date, "DD-MM-YYYY").format("DD MMMM")
                                }</span>
                        </div>
                    </div>
                    <div class="contracts-card-alt">
                        <div class="holidays-card-date">
                            <span class="holidays-card-date-light">{t("Start Date")}:</span>
                            <span class="holidays-card-date-bold">{row.start_date}</span>
                        </div>
                        <div class="holidays-card-date">
                            <span class="holidays-card-date-light">{t("End Date")} :</span>
                            <span class="holidays-card-date-bold">{row.end_date}</span>
                        </div>
                        <div class="holidays-card-date">
                            <span class="holidays-card-date-light">{t("Leave Type")}:</span>
                            <span class="holidays-card-date-bold">{row.leave_type}</span>
                        </div>
                        <div class="holidays-card-date">
                            <span class="holidays-card-date-light">{t("Status")}:</span>
                            <span class="holidays-card-date-bold">{row.status}</span>
                        </div>
                        <div class="holidays-card-date">
                            <span class="holidays-card-date-light">{t("Created Date")}:</span>
                            <span class="holidays-card-date-bold">{row.created_at}</span>
                        </div>
                    </div>
                </div>;
                })
            }
        </>
        }
    }
}

export default withTranslation("common")(RequestsViewer);