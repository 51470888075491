import { withTranslation } from "react-i18next";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import moment from "moment";

class DocumentsViewer extends ResultViewer {

    render() {
        const { t } = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
                <div className="folder-card-bg pb-3">
                    <ul className="folder-card-list">
                        {
                            this.state.data.data.map((row, index) => {
                                return <li>
                                    <a href={row.document} target="_blank" rel="noreferrer" className="w-100">
                                        <img src="/assets/images/icons/folder-icon.svg" alt={t("Folder")} />
                                        <span>{row.name}</span>
                                        <span className="float-end me-2 contracts-card-date-bold">
                                            {moment(row.start, "DD-MM-YYYY").format("DD.MM.YYYY")}&nbsp;-&nbsp;
                                            {row.expiring ? moment(row.expiring, "DD-MM-YYYY").format("DD.MM.YYYY") : "-"}
                                        </span>
                                    </a>
                                </li>;
                            })
                        }
                    </ul>

                </div>
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </>;
        }
    }
}

export default withTranslation("common")(DocumentsViewer);