import DocumentTitle from "react-document-title";
import NotificationViewer from "../../components/ResultViewer/NotificationViewer";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

function Notifications() {
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    let title = t("Notifications");
    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <NotificationViewer dataUrl={process.env.REACT_APP_API_URL + "/alerts/list"} editCallback={
                (id) => {
                    navigate("/requests/" + id);
                }
            } />
        </div>
    </section>;
}
export default Notifications;