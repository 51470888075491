import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

function Statistics() {

    const [startDate, setStartDate] = useState(
        moment().subtract("1", "week").format("YYYY-MM-DD")
    );

    const [endDate, setEndDate] = useState(
        moment().format("YYYY-MM-DD")
    );

    let [statistics, setStatistics] = useState({
        dateStatistics: {},
        abcentStatistics: {}
    });
    const {t} = useTranslation("common");
    function startChanged(value) {
        setStartDate(value);
        if(endDate <= value){
            setEndDate(
                moment(value, "YYYY-MM-DD").add("1", "week").format("YYYY-MM-DD")
            );
        }
    }

    function endChanged(value) {
        setEndDate(value);
        if(value <= startDate){
            setStartDate(
                moment(value, "YYYY-MM-DD").subtract("1", "week").format("YYYY-MM-DD")
            );
        }
    }

    useEffect(() => {
        axios.post(
            process.env.REACT_APP_API_URL + "/statistics/getStatistics",
            {
                startDate: startDate,
                endDate: endDate
            },
            { withCredentials: true }
        ).then((response) => {
            setStatistics(response.data.data);
        }).catch(function (error) {
        });
    }, [startDate, endDate])

    let title = t("Statistics");
    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton/>
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <div className="main-card-head">
                {t("Select Date")}
            </div>
            <div className="select-date-bg">
                <div className="row mt-2 mb-2">
                    <div className="col-6">
                        <div className="input-group">
                            <input type={"date"} className="form-control" placeholder="Start Date" value={startDate} onChange={(e) => startChanged(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="input-group">
                            <input type={"date"} className="form-control" placeholder="End Date" value={endDate} onChange={(e) => endChanged(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="contracts-card">
                <div className="contracts-card-header">
                    <div className="date-card-head">
                        <span>{t("Total Days")}</span>
                    </div>
                </div>
                <div className="contracts-card-alt">
                    <div className="date-card-text">
                        <span className="date-card-days-light">{t("Working Hours")} :</span>
                        <span className="date-card-days-bold">{ statistics.dateTotalDuration?.toFixed(2)} Hours</span>
                    </div>
                    <div className="date-card-text">
                        <span className="date-card-days-light">{t("Payroll")} :</span>
                        <span className="date-card-days-bold">£{ statistics.dateTotalPayroll?.toFixed(2)}</span>
                    </div>
                    <div className="date-card-text">
                        <span className="date-card-days-light">{t("Abcent Hours")} :</span>
                        <span className="date-card-days-bold">{ statistics.abcentTotalDuration?.toFixed(2) } Hours</span>
                    </div>
                </div>
            </div>
            <div className="accordion date-accordion mt-3" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            {t("Working Hours")}
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            {
                               Object.keys(statistics.dateStatistics).map((day, index) => {
                                 let dayStatistic = statistics.dateStatistics[day];
                                 return <div key={index} className="d-flex justify-content-between">
                                    <span className="date-accordion-body-span" style={{width: "100px", textAlign:"center"}}>
                                        { moment(day).format("DD-MM-YYYY") }
                                    </span>
                                    <span className="date-accordion-body-span">
                                        {t("? Hours", {hour: dayStatistic.duration.toFixed(2)})}
                                    </span>
                                    <span className="date-accordion-body-span">
                                        £{dayStatistic.payroll.toFixed(2)}
                                    </span>
                                 </div>;
                               })
                            }
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            {t("Abcent")}
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            {
                               Object.keys(statistics.abcentStatistics).map((day, index) => {
                                 let dayStatistic = statistics.abcentStatistics[day];
                                 return <div key={index} className="d-flex justify-content-between">
                                    <span className="date-accordion-body-span" style={{width: "100px", textAlign:"center"}}>
                                        { moment(day).format("DD-MM-YYYY") }
                                    </span>
                                    <span className="date-accordion-body-span">
                                        {dayStatistic.title}
                                    </span>
                                    <span className="date-accordion-body-span">
                                        {t("? Hours", {hour: dayStatistic.duration.toFixed(2)})}
                                    </span>
                                 </div>;
                               })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}
export default Statistics;