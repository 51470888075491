import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import { withTranslation } from "react-i18next";

class NewsViewer extends ResultViewer {

    render() {
        const {t} = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <div className="messages-card-bg">
                <ul className="messages-card-list">
                    {
                        this.state.data.data.map((row, index) => {
                            return <li key={index}>
                                <Link to={"/news/" + row.ID}>
                                    <div className="messages-card-div-bg">
                                        <div className="messages-card-div-left">
                                            <img src="assets/images/icons/messages-icon.svg" alt="Messages"/>
                                        </div>
                                        <div className="messages-card-div-right">
                                            <div className="messages-card-head">
                                                {row.subject}
                                            </div>
                                            <div className="messages-card-alt">
                                                <span className="light">{t("From")}:</span><span className="bold">{row.from}</span>
                                            </div>
                                            <div className="messages-card-alt">
                                                <span className="light">{t("Date")}:</span><span className="bold">{row.created_at}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>;
                        })
                    }
                </ul>
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </div>;
        }
    }
}

export default withTranslation("common")(NewsViewer);