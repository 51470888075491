import axios from "axios";
import { useState } from "react";
import CsrfHandler from "./CsrfHandler";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function RequestForm(props) {

    let apiUrl = process.env.REACT_APP_API_URL + "/requests/crud/" + props.requestid;
    const { t } = useTranslation("common");

    const leaveTypeOptions = {
        "annual_leave" : t("Annual Leave"),
        "unpaid_leave" : t("Unpaid Leave"),
    };
    const [buttonText, setButtonText] = useState(t("Create Request"));
    const [successMessage, setSuccessMessage] = useState(t("Request created successfully"));
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [leaveType, setLeaveType] = useState("annual_leave");
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("pending");
    const csrfHandler = new CsrfHandler(apiUrl);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.requestid !== "add") {
            setButtonText(t("Save"));
            setSuccessMessage(t("Request updated successfully"));
            let singleApi = process.env.REACT_APP_API_URL + "/requests/single/" + props.requestid;
            axios.get(singleApi, {
                withCredentials: true
            }).then((response) => {
                let data = response.data.data;
                setStart(data.start_date);
                setEnd(data.end_date);
                setLeaveType(data.leave_type);
                setComment(data.comment);
                setStatus(data.status);
            }).catch((error) => { });
        }
    }, [props, t]);

    const handleSubmit = (event, deleteFlag = false) => {
        event.preventDefault();
        if(!start){
            Swal.fire({
                title: t('Error'),
                text: t("You must select start date"),
                icon: 'warning',
                confirmButtonColor: '#d33',
                confirmButtonText: t("OK")
            });
            return;
        }
        if(!end){
            Swal.fire({
                title: t('Error'),
                text: t("You must select end date"),
                icon: 'warning',
                confirmButtonColor: '#d33',
                confirmButtonText: t("OK")
            })
            return;
        }
        if(!comment){
            Swal.fire({
                title: t('Error'),
                text: t("You must enter some comment for request"),
                icon: 'warning',
                confirmButtonColor: '#d33',
                confirmButtonText: t("OK")
            })
            return;
        }
        let operation = deleteFlag ? {
            delete: 1,
        } : {
            save: 1,
        };
        let data = {
            day_off_requests: {
                start_date: start,
                end_date: end,
                leave_type: leaveType,
                comment: comment,
            },
            ...operation,
            ...csrfHandler.getTokenForRequest()
        };
        axios.post(apiUrl, data, { withCredentials: true })
            .then((response) => {
                Swal.fire("Success",
                    deleteFlag ? t("Request deleted successfully") : successMessage,
                    "success"
                );
                navigate("/requests");
            }).catch((error) => {
                if(error.response.data.messages){
                    Swal.fire({
                        title: t('Error'),
                        text: Object.values(error.response.data.messages[0])
                                .join("<br\\>"),
                        icon: 'error',
                        confirmButtonColor: '#d33',
                        confirmButtonText: t("OK")
                    })
                }
             });;
    }

    const handleDelete = (event) => {
        event.preventDefault();
        Swal.fire({
            title: t("Are you sure want to delete?"),
            text: t("You won't be able to revert this!"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(event, true);
            }
        })
    }

    return <div className="select-date-bg">
        <form className="row mt-2 mb-2 mx-0">
            <div className="col-12">
            {
                status !== "pending" ?
                    <div className={"alert alert-" + (status === "approved" ? "success" : "danger")}>
                        <span className={"fa fa-" + (status === "approved" ? "check" : "exclamation-triangle")}></span>
                        {t("This request has been {{status}}", {status: (status === "approved" ? "Approved" : "Rejected")})}
                    </div> : (
                        props.requestid !== "add" ? <div className="alert alert-warning">
                            <span className={"fa fa-cloc"}></span>
                            {t("Waiting Approval")}
                        </div> : <></>
                    )
            }
            </div>
            <div className="col-6">
                <div className="mt-2">
                    <label className="date-label form-label">{t("Start Date")}</label>
                </div>
                <div className="input-group">
                    <input type="date" className="form-control" onChange={(e) => setStart(e.target.value)} value={start} />
                </div>
            </div>
            <div className="col-6">
                <div className="mt-2">
                    <label className="date-label form-label">{t("End Date")}</label>
                </div>
                <div className="input-group">
                    <input type="date" className="form-control" onChange={(e) => setEnd(e.target.value)} value={end} />
                </div>
            </div>
            <div className="col-6">
                <div className="mt-2">
                    <label className="date-label form-label">{t("Leave Type")}</label>
                </div>
                <div className="input-group">
                    <select className="form-control" onChange={(e) => setLeaveType(e.target.value)} value={leaveType}>
                        {
                            Object.keys(leaveTypeOptions).map((key) => {
                                return <option key={key} value={key}>{leaveTypeOptions[key]}</option>;
                            })
                        }
                    </select>
                </div>
            </div>
            <div className="col-12">
                <div className="mt-2">
                    <label className="date-label form-label">{t("Comment")}</label>
                </div>
                <div className="input-group">
                    <textarea className="form-control h-100" rows="7" placeholder="Leave a comment here" onChange={(e) => setComment(e.target.value)} value={comment}></textarea>
                </div>
            </div>
            {status === "pending" ?
                <div className="col-12 mt-3">
                    <div className="holidays-card-buttons">
                        <button type="submit" className="holidays-card-button" onClick={(e) => handleSubmit(e)}>
                            <img src="/assets/images/icons/plus-white.png" alt="Plus icon"/>{buttonText}
                        </button>
                        <button type="submit" className="holidays-card-delete" onClick={(e) => handleDelete(e)} >
                            <img src="/assets/images/icons/delete.png" alt ="Delete icon" /> {t("Delete")}
                        </button>
                    </div>
                </div> : <></>
            }
        </form>
    </div>
}

export default RequestForm;