import { Link, useNavigate } from "react-router-dom";
import { AuthConsumer } from "../AuthContext";
import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";

function Home() {
    let navigate = useNavigate();
    const [t] = useTranslation('common');
    return (
        <AuthConsumer>
            {({ user }) => (
                <>
                    {user.logged_in ? (
                        navigate("/welcome")
                    ) : (
                        <div className="splash-main">
                            <DocumentTitle title={t("Welcome to", {app: process.env.REACT_APP_NAME})}></DocumentTitle>
                            <div className="splash-main-logo">
                                <img src="assets/images/main/logo.svg" alt={process.env.REACT_APP_NAME}/>
                            </div>
                            <div className="splash-main-button">
                                <Link to="/login">
                                    <button>{t("Login")}</button>
                                </Link>
                            </div>
                        </div>
                    )}
                </>
            )}
        </AuthConsumer>
    )
}

export default Home;