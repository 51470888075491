import DocumentTitle from "react-document-title";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";
import PayslipsViewer from "../../components/ResultViewer/PayslipsViewer";

function Payslips() {
    const [t] = useTranslation('common');
    let title = t("Payslips");

    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton/>
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <PayslipsViewer dataUrl={process.env.REACT_APP_API_URL + "/payslips/list"} />
        </div>
    </section>;
}

export default Payslips;