import { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import DiffLabel from "./DiffLabel";
import BreakControl from "./BreakControl";
import moment from "moment";
import { withTranslation } from "react-i18next";

class PunchControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeShift: null,
            punchedIn: false,
            punchInTime: ".. : ..",
            punchOutTime: ".. : ..",
        };
    }

    componentDidMount() {
        this.getUserActiveShift();
    }

    getUserActiveShift() {
        axios.get(process.env.REACT_APP_API_URL + "/getUserActiveShift", { withCredentials: true })
            .then((response) => {
                this.setState({
                    activeShift: response.data.data
                }, () => {
                    if (this.state.activeShift != null) {
                        if (!(this.state.activeShift?.punch_start == null ||
                            this.state.activeShift?.punch_end != null
                        )) {
                            this.setState({
                                punchedIn: true
                            });
                        } else {
                            this.setState({
                                punchedIn: false
                            });
                        }
                        this.setState({
                            punchInTime: this.state.activeShift.punch_start ?
                                moment.utc(this.state.activeShift.punch_start).format("HH:mm:ss") : ".. : ..",
                            punchOutTime:
                                this.state.activeShift.punch_end ?
                                    moment.utc(this.state.activeShift.punch_end).format("HH:mm:ss") : ".. : .."
                        });
                    } else {
                        this.setState({
                            punchedIn: false
                        });
                    }
                });
            })
            .catch(function (error) {
            });
    }

    getLocationPermission(callback = null) {
        Swal.showLoading();
        const { t } = this.props;
        var geolocationId = navigator.geolocation.watchPosition(
            function (pos) {
                if (callback) {
                    callback(pos.coords.longitude, pos.coords.latitude);
                    Swal.close();
                    navigator.geolocation.clearWatch(geolocationId);
                }
            },
            function (err) {
                if (err.code === 1) {
                    Swal.fire(
                        t("Warning"),
                        t("Need location permission for this operation"),
                        "warning"
                    );
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
        return geolocationId;
    };

    punchIn() {
        const { t } = this.props;
        this.getLocationPermission((longitude, latitude) => {
            let formData = new FormData();
            formData.append("longitude", longitude);
            formData.append("latitude", latitude);
            axios.post(process.env.REACT_APP_API_URL + "/punchIn", formData, { withCredentials: true })
                .then((response) => {
                    let data = response.data.data;
                    if (data && data.status === "active_shift_not_exist") {
                        Swal.fire({
                            confirmButtonText: t("Create Shift and Punch In"),
                            showCancelButton: true,
                            title: data.title,
                            html: data.message,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.showLoading();
                                let htmlContainer = Swal.getHtmlContainer();
                                let form = htmlContainer.getElementsByTagName("form")[0];
                                let formData = new FormData(form);
                                axios.post(process.env.REACT_APP_API_URL + "/createPunchInShift", formData, { withCredentials: true })
                                    .then((response) => {
                                        this.punchIn();
                                    })
                            }
                        });
                    } else {
                        Swal.fire(t("You have punched in successfully"));
                        this.getUserActiveShift();
                    }
                })
                .catch(function (error) {
                });
        })
    }

    punchOut() {
        const { t } = this.props;
        this.getLocationPermission((longitude, latitude) => {
            let formData = new FormData();
            formData.append("longitude", longitude);
            formData.append("latitude", latitude);
            axios.post(process.env.REACT_APP_API_URL + "/punchOut", formData, { withCredentials: true })
                .then((response) => {
                    Swal.fire(t("You have punched out successfully"));
                    this.getUserActiveShift();
                }).catch((error) => {
                    Swal.fire(
                        t("Warning"),
                        error.response.data.messages[0].join(""),
                        "error"
                    );
                })
        })
    }

    makeComment() {
        const { t } = this.props;
        Swal.fire({
            title: t("Please enter your comment about the shift below"),
            input: 'textarea',
            inputValue: this.state.activeShift?.comment,
            showCancelButton: true,
            cancelButtonText: t('Cancel'),
            confirmButtonText: t('Save'),
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                let comment = result.value;
                axios.post(
                    process.env.REACT_APP_API_URL + "/makeCommentToActiveShift",
                    { comment },
                    { withCredentials: true }
                ).then((response) => {
                    this.getUserActiveShift();
                })
                    .catch((error) => {
                        Swal.fire(
                            t("Warning"),
                            error.response.data.messages[0].join(""),
                            "error"
                        );
                    })
            }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="home-punch">
                    <div className="punch-big-round" role={"button"} onClick={(e) => {
                        e.preventDefault();
                        this.state.punchedIn ? this.punchOut() : this.punchIn()
                    }}>
                        <div className="punch-middle-round">
                            <div className="punch-small-round">
                                <span>{this.state.punchedIn ? t("Punch Out") : t("Punch In")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-time">
                    <div className="home-time-span">
                        <DiffLabel start={this.state.activeShift?.punch_start} />
                    </div>
                </div>
                {
                    this.state.activeShift?.punch_start && !this.state.activeShift?.punch_end ? <>
                        <div className="home-take">
                            <button onClick={() => this.makeComment()} className="bg-primary" title={t("Comment")}>
                                <span className="fa fa-comment fs-1"></span>
                            </button>
                        </div>
                        <BreakControl />
                    </> : ""
                }
            </>
        );
    }
}

export default withTranslation("common")(PunchControl);