import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

function ProfileForm(props) {
    let user = props.user;

    let [currentPassword, updateCurrentPassword] = useState('');
    let [newPassword, updateNewPassword] = useState('');
    let [confirmPassword, updateConfirmPassword] = useState('');
    const { t } = useTranslation("common");

    const changePassword = function (e) {
        e.preventDefault();
        if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
            Swal.fire(
                t("Warning"),
                t("Passwords not match"),
                "warning"
            );
        } else {
            let changePasswordUrl = process.env.REACT_APP_API_URL + "/profile/changePassword";
            axios.post(changePasswordUrl, {
                current_password: currentPassword,
                password: newPassword
            }, { withCredentials: true })
                .then((response) => {
                    Swal.fire(
                        t("Success"),
                        t("Password changed successfully"),
                        "success"
                    );
                }).catch((error) => {
                    Swal.fire(
                        t("Error"),
                        error.response.data.messages[0].join("<br>"),
                        "error"
                    );
                })
        }
    }
    return <div className="messages-card-bg">
        <div className="messages-card-list p-3">
            <div className={"messages-card-div-bg"}>
                <div className="messages-card-div-right">
                    <div className="messages-card-head">
                        {t("Username")}
                    </div>
                    <div className="messages-card-alt">
                        <span className="light">{user.username ?? ""}</span>
                    </div>
                </div>
            </div>
            <div className={"messages-card-div-bg"}>
                <div className="messages-card-div-right">
                    <div className="messages-card-head">
                        {t("Title")}
                    </div>
                    <div className="messages-card-alt">
                        <span className="light">{user.title ?? ""}</span>
                    </div>
                </div>
            </div>
            <div className={"messages-card-div-bg"}>
                <div className="messages-card-div-right">
                    <div className="messages-card-head">
                        {t("Name")}
                    </div>
                    <div className="messages-card-alt">
                        <span className="light">{user.name ?? ""}</span>
                    </div>
                </div>
            </div>
            <div className={"messages-card-div-bg"}>
                <div className="messages-card-div-right">
                    <div className="messages-card-head">
                        {t("Surname")}
                    </div>
                    <div className="messages-card-alt">
                        <span className="light">{user.surname ?? ""}</span>
                    </div>
                </div>
            </div>
            <div className={"messages-card-div-bg"}>
                <div className="messages-card-div-right">
                    <div className="messages-card-head">
                        {t("Job Title")}
                    </div>
                    <div className="messages-card-alt">
                        <span className="light">{user.job_title ?? ""}</span>
                    </div>
                </div>
            </div>
            <div className={"messages-card-div-bg"}>
                <form className="w-100">
                    <div className="mb-3">
                        <label className="form-label"></label>
                        <input className="form-control" placeholder={t("Current Password")} type={"password"} value={currentPassword} onChange={(e) => updateCurrentPassword(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"></label>
                        <input className="form-control" placeholder={t("New Password")} type={"password"} value={newPassword} onChange={(e) => updateNewPassword(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label"></label>
                        <input className="form-control" placeholder={t("Confirm Password")} type={"password"} value={confirmPassword} onChange={(e) => updateConfirmPassword(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <button className="btn bg-secondary text-light" onClick={(e) => changePassword(e)}>
                            <i className="fa fa-lock"></i>
                            <span className="p-2 fs-6">{t("Change Password")}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

export default ProfileForm;