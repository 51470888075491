import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Scheduler from "./pages/LoginPages/Scheduler";
import Login from "./pages/Login";
import { Component } from "react";
import { AuthProvider } from './AuthContext';
import Requests from "./pages/LoginPages/Requests";
import RequestEdit from "./pages/LoginPages/RequestEdit";
import Notifications from "./pages/LoginPages/Notifications";
import Profile from "./pages/LoginPages/Profile";
import News from "./pages/LoginPages/News";
import NewsView from "./pages/LoginPages/NewsView";
import LoginLayout from "./pages/LoginPages/LoginLayout";
import Welcome from "./pages/LoginPages/Welcome";
import People from "./pages/LoginPages/People";
import Documents from "./pages/LoginPages/Documents";
import ContractDetail from "./pages/LoginPages/ContractDetail";
import PeopleDetail from "./pages/LoginPages/PeopleDetail";
import ForgetPassword from "./pages/ForgetPassword";
import Statistics from "./pages/LoginPages/Statistics";
import GroupDetail from "./pages/LoginPages/GroupDetail";
import DepartmentDetail from "./pages/LoginPages/DepartmentDetail";
import Payslips from "./pages/LoginPages/Payslips";

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot_password" element={<ForgetPassword />} />
            </Route>
            <Route element={<LoginLayout />}>
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/scheduler" element={<Scheduler />} />
              <Route path="/requests" element={<Requests />} />
              <Route path="/requests/:id" element={<RequestEdit />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/people" element={<People />} />
              <Route path="/people/:list" element={<People />} />
              <Route path="/people/employee/:username" element={<PeopleDetail />} />
              <Route path="/people/group/:group_name" element={<GroupDetail />} />
              <Route path="/people/department/:department_name" element={<DepartmentDetail />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:id" element={<NewsView />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/documents/contracts/:id" element={<ContractDetail />} />
              <Route path="/payslips" element={<Payslips />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

export default App;
