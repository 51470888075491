import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

function NewsView() {
    const [t] = useTranslation('common');
    let [subject, setSubject] = useState(t("Subject"));
    let [from, setFrom] = useState(t("From"));
    let [message, setMessage] = useState(t("Message"));
    let [requireConfirmation, setRequireConfirmation] = useState(false);
    let [createdAt, setCreatedAt] = useState("");
    let [isRead, setIsRead] = useState(true);
    let params = useParams();
    useEffect(() => {
        let singleApi = process.env.REACT_APP_API_URL + "/news/single/" + params.id;
        axios.get(singleApi, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            setSubject(data.subject);
            setFrom(data.from);
            setMessage(data.content);
            setRequireConfirmation(data.require_confirmation);
            setIsRead(data.is_read);
            setCreatedAt(data.created_at);
        }).catch((error) => { });
    }, [params.id]);

    function confirmNews() {
        let confirmNewsApi = process.env.REACT_APP_API_URL + "/confirmNews";
        axios.post(confirmNewsApi, { news: params.id }, { withCredentials: true }).then((response) => {
            setIsRead(true);
            Swal.fire({
                title: t("Success"),
                html: response.data.messages[3].join("<br>"),
                icon: "success",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                position: 'top-end',
                showConfirmButton: false,
                customClass: {
                    popup: "bg-secondary"
                }
            }
            );
        });
    }

    return <section className="main-bg">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton/>
            </div>
            <div className="main-header-text">
                <DocumentTitle title={subject}>
                    <h1>{subject}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <div className="messages-detail-card-bg">
                <div className="messages-detail-head">{subject}</div>
                <div className="messages-detail-desc" dangerouslySetInnerHTML={{ __html: message }}></div>
                <div className="messages-detail-alt">
                    <div className="messages-detail-alt-span">
                        <span className="light">{t("From")}:</span>
                        <span className="bold">{from}</span>
                    </div>
                    <div className="messages-detail-alt-span">
                        <span className="light">{t("Date")}:</span>
                        <span className="bold">{
                            moment(createdAt, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY HH:mm:ss")
                        }</span>
                    </div>
                </div>
                {
                    requireConfirmation && !isRead ? (
                        <div className="messages-detail-button">
                            <button onClick={() => confirmNews()}>
                                <img src="/assets/images/icons/chech-icon.svg" alt={t("Confirm")}/><span>{t("Confirm")}</span>
                            </button>
                        </div>
                    ) : ""
                }
            </div>
        </div>
    </section>;
}

export default NewsView;