import axios from "axios";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Link, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import RequestsViewer from "../../components/ResultViewer/RequestsViewer";
import { useTranslation } from "react-i18next";

function Requests() {
  const navigate = useNavigate();

  let [absenceAccount, setAbsenceAccount] = useState({});
  const {t} = useTranslation("common");

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/requests/getAbsenceAccount", { withCredentials: true })
            .then((response) => {
                setAbsenceAccount(response.data.data);
            })
            .catch(function (error) {
            });
  }, [])

  let title = t("Holidays");
  return <section className="main-bg">
    <div className="main-header-head">
      <div className="main-header-left">
        <BackButton />
      </div>
      <div className="main-header-text">
        <DocumentTitle title={title}>
          <h1>{title}</h1>
        </DocumentTitle>
      </div>
      <div className="main-header-right"></div>
    </div>
    <div className="main-body mt-3 px-3">
      <div className="contracts-all-bg">
        <div className="row">
          <div className="col-md-6 col-6 mb-4">
            <div className="contracts-all-card">
              <h6 style={{whiteSpace: "pre-line"}}>{t("Days Remaining")}</h6>
              <span>{absenceAccount?.available_balance}</span>
            </div>
          </div>
          <div className="col-md-6 col-6 mb-4">
            <div className="contracts-all-card">
              <h6 style={{whiteSpace: "pre-line"}}>{t("Days Used")}</h6>
              <span>{absenceAccount?.balance_used}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="contracts-card-button text-end">
        <Link to={"/requests/add"}>
          <img src="/assets/images/icons/plus-white.png" alt="Plus icon" />
          {t("Create New Request")}
        </Link>
      </div>
      <RequestsViewer dataUrl={process.env.REACT_APP_API_URL + "/requests/list"} editCallback={
        (id) => {
          navigate("/requests/" + id);
        }
      } />
    </div>
  </section>;
}
export default Requests;