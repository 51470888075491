import React, { useEffect, useState } from 'react'
import moment from "moment";
import axios from 'axios';
import DocumentTitle from "react-document-title";
import ShiftDetailDrawer from '../../components/ShiftDetailDrawer';
import { useTranslation } from 'react-i18next';

const Scheduler = () => {

  let [activeDate, setActiveDate] = useState(moment());
  let [detailShift, setDetailShift] = useState(null);
  let [departments, setDepartments] = useState({});
  let [activeDepartment, setActiveDepartment] = useState(null);

  let [dates, setDates] = useState([
    activeDate.subtract(1, "day"),
    activeDate,
    activeDate.add(1, "day")
  ]);
  let [employeeGroups, setEmployeeGroups] = useState([]);

  const {t} = useTranslation("common");
  useEffect(() => {
    const getShifts = () => {
      axios.get(
        process.env.REACT_APP_API_URL + "/getShiftsForMySchedule", {
        withCredentials: true,
        params: {
          date: activeDate.format("YYYY-MM-DD"),
          department: activeDepartment
        }
      }).then((response) => {
        setEmployeeGroups(response.data.data.employee_groups);
      }).catch((error) => { });
      setDates([
        activeDate.clone().subtract(1, "day"),
        activeDate.clone(),
        activeDate.clone().add(1, "day")
      ]);
    }
    axios.get(
      process.env.REACT_APP_API_URL + "/getDepartments", {
      withCredentials: true
    }).then((response) => {
      setDepartments(response.data.data ?? []);
    })
    getShifts();
  }, [activeDate, activeDepartment])

  let title = t("Schedule");
  return (
    <>
      <DocumentTitle title={title}></DocumentTitle>
      <div className="container mt-3">
        <div className="container-fluid d-flex justify-content-between bg-primary mb-3">
          <h3 className="text-white text-date-head my-auto">{
            activeDate.format("MMM YYYY")
          }</h3>
          <div>
            <select className='form-select bg-primary text-light' onChange={(e) => { setActiveDepartment(e.target.value) }}>
              <option value={""}>{t("Select a department")}</option>
              {
                Object.keys(departments).map((key) => {
                  return <option value={key} key={key}>{departments[key]}</option>;
                })
              }
            </select>
          </div>
        </div>
        <div className="carousel slide bg-primary">
          <div className="d-flex justify-content-around text-white">
            {
              dates.map((time, index) => {
                return <div role="button" className={"p-1 text-center flex-fill " + (
                  time.format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") ? " fw-bolder fs-6" : ""
                )} key={index}>
                  {time.format("ddd")}<br />
                  <span>{time.format("D")}</span>
                </div>;
              })
            }
          </div>
          <button className="carousel-control-prev" type="button" onClick={(e) => {
            setActiveDate(activeDate.clone().subtract(3, "day"))
          }}>
            <span className='fa fa-chevron-left fw-bolder'></span>
            <span className="visually-hidden">{t("Previous")}</span>
          </button>
          <button className="carousel-control-next" type="button" onClick={(e) => {
            setActiveDate(activeDate.clone().add(3, "day"))
          }}>
            <span className='fa fa-chevron-right fw-bolder'></span>
            <span className="visually-hidden">{t("Nex")}</span>
          </button>
        </div>
      </div>
      <div className="container bg-white pt-3 pb-5">
        {
          employeeGroups.map((group) => {
            let dates = group.dates;
            return <div key={group.ID}>
              <div className="row align-items-start">
                <h5 className="text-title col ps-3">
                  <img src="/assets/images/icons/people-user-icon.svg" alt="Employee Group" className='me-2' />
                  {group.name}
                </h5>
              </div>
              <div className="container">
                <div className="row">
                  {
                    [0, 1, 2].map((index) => {
                      let shifts = dates[index];
                      return <div className='col-4' key={index}>
                        <div className='row p-1'>
                          {
                            shifts.map((shift) => {
                              let shiftProps = {
                                className: "col-12 mb-1 p-2 rounded text-start border shadow-lg ",
                                role: "button",
                                onClick: function () {
                                  setDetailShift(shift);
                                }
                              };
                              if (shift.is_approved) {
                                shiftProps.className += "bg-primary text-light";
                              } else if (shift.abcent) {
                                shiftProps.className += "text-light";
                                shiftProps.style = {
                                  backgroundColor: shift.backgroundColor
                                };
                              } else if (shift.punch_start || shift.punch_end) {
                                shiftProps.className += "bg-light text-dark";
                              } else {
                                shiftProps.className += "bg-secondary text-light";
                              }
                              return <div {...shiftProps} key={shift.id}>
                                <h6 className='fs-12px'>{shift.title}</h6>
                                <p className='fs-12px text-nowrap'>
                                  {
                                    shift.start ?
                                      moment(shift.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : "..:.."
                                  }
                                  -
                                  {
                                    shift.end ?
                                      moment(shift.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm") : "..:.."
                                  }
                                </p>
                              </div>;
                            })
                          }
                        </div>
                      </div>
                    })
                  }
                  <hr className='border border-primary border-2' />
                </div>
              </div>
            </div>;
          })
        }
        <ShiftDetailDrawer detailShift={detailShift} setDetailShift={setDetailShift} />
      </div>
    </>
  )
}

export default Scheduler;