import { Outlet } from "react-router-dom";


const Layout = () => {
    return (
        <section className="splash-bg">
            <div className="splash-header">
                <img className="img-fluid" src="assets/images/login/splash-header.png" alt="Splash Header"/>
            </div>
            <Outlet />
            <div className="splash-bottom">
                <img className="img-fluid" src="assets/images/login/splash-bottom.png" alt="Splash Bottom"/>
            </div>
        </section >
    )
};

export default Layout;