import axios from "axios";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import Chatbox from "../../components/Chatbox";
import { useTranslation } from "react-i18next";

function DepartmentDetail() {
    let params = useParams();
    let [department, setDeparment] = useState({});
    const [t] = useTranslation('common');
    let [title, setTitle] = useState(t("Department Detail"));
    let user = typeof window.user == "function" ? window.user() : null;

    useEffect(() => {
        let singleApi = process.env.REACT_APP_API_URL + "/people/departments?name=" + params.department_name;
        axios.get(singleApi, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data.data[0];
            setDeparment(data);
            setTitle(data.name);
        }).catch((error) => { });
    }, [params.department_name]);

    return <section className="main-bg mb-0">
        <div className="main-header-head">
            <div className="main-header-left">
                <BackButton />
            </div>
            <div className="main-header-text">
                <DocumentTitle title={title}>
                    <h1>{title}</h1>
                </DocumentTitle>
            </div>
            <div className="main-header-right"></div>
        </div>
        <div className="main-body mt-3 p-2">
            <div className="people-detail-card-bg">
                <div className="bg-secondary text-light rounded rounded-circle">
                    <span className="fa fa-users fs-5 m-2"></span>
                </div>
                <div className="people-detail-card-text">
                    <div className="people-detail-card-text-name">
                        {title}
                    </div>
                </div>
            </div>
            {
                department?.ID && user?.employee_department === department.ID ?
                <Chatbox recipientDepartment={department} type={"department"}/> :
                <></>
            }
        </div>
    </section>;
}

export default DepartmentDetail;