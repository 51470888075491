import axios from "axios";

class CsrfHandler {
    constructor(apiUrl) {
        this.apiUrl = apiUrl;
        this.form_id = "";
        this.form_build_id = "";
        this.form_token = "";
        this.refreshCsrf();
    }

    refreshCsrf() {
        axios.get(this.apiUrl, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            this.form_id = data.form_id;
            this.form_build_id = data.form_build_id;
            this.form_token = data.form_token;
        }).catch((error) => { });
    }

    getTokenForRequest() {
        let token = {
            form_id: this.form_id,
            form_build_id: this.form_build_id,
            form_token: this.form_token,
        };
        this.refreshCsrf();
        return token;
    }
}

export default CsrfHandler;