import { Component } from "react";
import axios from "axios";
import { AuthConsumer } from "../AuthContext";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";

class ForgetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            email: "",
            form_id: "",
            form_build_id: "",
            form_token: ""
        }
    }

    componentDidMount() {
        this.getCsrf();
    }

    getCsrf() {
        axios.get(process.env.REACT_APP_API_URL + "/login/getCsrfForForgotPasswordForm", { withCredentials: true })
            .then((response) => {
                this.setState({
                    form_id: response.data.data.form_id,
                    form_build_id: response.data.data.form_build_id,
                    form_token: response.data.data.form_token,
                });
            })
            .catch(function (error) {
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        for (let index in {
            email: this.state.email,
            form_id: this.state.form_id,
            form_build_id: this.state.form_build_id,
            form_token: this.state.form_token
        }) {
            formData.append(index, this.state[index]);
        }
        Swal.showLoading();
        axios.post(process.env.REACT_APP_API_URL + "/login/submitForgotPasswordForm", formData, { withCredentials: true })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        errorMessage: null
                    });
                    Swal.fire({
                        title: "Password reset e-mail has been sent",
                        text: "Please check your email to follow directions.",
                        icon: "success",
                    }).then((response) => {
                        window.location.assign("/");
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error.response.data
                });
            });
        this.getCsrf();
    }

    render() {
        const { t } = this.props;
        let title = t("Forgot Password");
        return (
            <AuthConsumer>
                {({ user }) => (
                    <div>
                        {user.logged_in ? (
                            <div>
                                {window.location.assign("/")}
                            </div>
                        ) : (
                            <form className="login-main">
                                <DocumentTitle title={title}></DocumentTitle>
                                <div className="login-main-logo">
                                    <img src="assets/images/main/logo.svg" alt={process.env.REACT_APP_NAME}/>
                                </div>
                                <div className="login-form">
                                    <div className="login-head-text">
                                        <h1>{title}</h1>
                                        <p>{t("Please enter your email to reset your password")}</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div>
                                    <div className="login-username">
                                        <input type="email" className="form-control" placeholder="E-mail" onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }} />
                                    </div>

                                    <div className="login-form-alt">
                                        <div className="login-remember">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Remember" onChange={(e) => {
                                                    this.setState({ rememberMe: e.target.checked })
                                                }} />
                                                <label className="form-check-label" htmlFor="Remember">{t("Remember me")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="login-main-button mt-5 text-center">
                                    <button onClick={(e) => this.handleSubmit(e)}>{t("Reset Password")}</button>
                                </div>
                                <div className="login-forgot mt-5 text-center">
                                    <Link to={"/login"} className="btn btn-link">{t("Login")}</Link>
                                </div>
                            </form>
                        )}
                    </div>
                )}
            </AuthConsumer>

        );
    }
}

const ForgetPassword = withTranslation("common")(ForgetPasswordPage);

export default ForgetPassword;