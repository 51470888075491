import Pagination from "./Pagination";
import ResultViewer from "./ResultViewer";
import { withTranslation } from "react-i18next";

class PayslipsViewer extends ResultViewer {

    render() {
        const { t } = this.props;
        if (!this.state.data) {
            return <>
                {t("Loading...")}
            </>;
        } else {
            return <>
                <div className="messages-card-bg">
                    <ul className="messages-card-list">
                        {
                            this.state.data.data.map((row, index) => {
                                return <li key={index} className="h-auto">
                                    <a href={row.payslip_file.url} target="_blank" rel="noreferrer">
                                        <div className="messages-card-div-bg">
                                            <div className="messages-card-div-left">
                                                <img src="assets/images/icons/mail-icon.svg" alt="Messages" />
                                            </div>
                                            <div className="messages-card-div-right">
                                                <div className="messages-card-head" style={{
                                                    color: 'var(--orange)'
                                                }}>
                                                    <span className="fa fa-file-pdf me-2"></span>
                                                    <span>{row.payslip_file.file_name}</span>
                                                </div>
                                                <div className="messages-card-alt">
                                                    <span className="light">{t("Start Date")} - {t("End Date")}:</span>
                                                </div>
                                                <div className="messages-card-alt">
                                                    <span className="light">{row.start}-{row.end}</span>
                                                </div>
                                                <div className="messages-card-alt">
                                                    <span className="light">{t("Created Date")}:</span><span className="bold">{row.created_at}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>;
                            })
                        }
                    </ul>
                </div>
                <Pagination {...this.state.data.pagination}
                    setPage={this.setPage}
                />
            </>;
        }
    }
}

export default withTranslation("common")(PayslipsViewer);