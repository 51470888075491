import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import "./PdfViewer.scss";
import { pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PdfViewer({ pdfUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { t } = useTranslation("common");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

  return (
    <div>
      <nav className='d-flex justify-content-around mb-3'>
        <button onClick={goToPrevPage} className="btn bg-secondary text-light">
          <span className='fa fa-chevron-left'></span>
          { t("Previous") }
        </button>
        <span className='text-light'>
        { t("Page {{pageNumber}} of {{numPages}}", {pageNumber: pageNumber, numPages: numPages}) }
        </span>
        <button onClick={goToNextPage} className="btn bg-secondary text-light">
          <span className='fa fa-chevron-right'></span>
          { t("Next") }
        </button>
      </nav>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
      </Document>
      <a href={pdfUrl} target="_blank" rel="noreferrer"
        className="btn bg-secondary text-light text-center btn-lg w-100 p-3 mb-5 mt-3">
        <span className="fa fa-file-pdf fa-1x"></span>
        <span> {t("Download Document")}</span>
      </a>
    </div>
  );
}

export default PdfViewer;