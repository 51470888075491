import { Component } from "react";
import { withTranslation } from "react-i18next";

class Pagination extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: props.page,
            limit: props.limit,
            total_count: props.total_count,
        };
    }

    render() {
        const {t} = this.props;
        let totalPage = parseInt(
            this.state.total_count / this.state.limit + 1
        );
        return <div className="pagination-card-bg">
            <nav>
                <ul className="pagination">
                    <li className={"page-item " + (this.state.page <= 1 ? "disabled" : "")}>
                        <button className="page-link" onClick={() => this.props.setPage(1)}>
                            <span className="sr-only">{t("First")}</span>
                            <i className="fa fa-angle-double-left"></i>
                        </button>
                    </li>
                    <li className={"page-item " + (this.state.page <= 1 ? "disabled" : "")}>
                        <button className="page-link" onClick={() => this.props.setPage(this.state.page - 1)}>
                            <span className="sr-only">{t("Previous")}</span>
                            <i className="fa fa-angle-left"></i>
                        </button>
                    </li>
                    {
                        [...Array(5).keys()].map((index) => {
                            let currentPage = this.state.page - 2 + index;
                            if (currentPage < 1 || currentPage > totalPage) {
                                return <div key={index}></div>;
                            }
                            return <li className={"page-item " + (this.state.page === currentPage ? "active" : "")} key={index}>
                                <button className="page-link" onClick={() => this.props.setPage(currentPage)}>
                                    <span>{this.state.page - 2 + index}</span>
                                </button>
                            </li>
                        })
                    }
                    <li className={"page-item " + (this.state.page >= totalPage ? "disabled" : "")}>
                        <button className="page-link" onClick={() => this.props.setPage(this.state.page + 1)}>
                            <span className="sr-only">{t("Next")}</span>
                            <i className="fa fa-angle-right"></i>
                        </button>
                    </li>
                    <li className={"page-item " + (this.state.page >= totalPage ? "disabled" : "")}>
                        <button className="page-link" onClick={() => this.props.setPage(totalPage)}>
                            <span className="sr-only">{t("Last")}</span>
                            <i className="fa fa-angle-double-right"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>;
    }
}
export default withTranslation("common")(Pagination);