import axios from "axios";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { AuthConsumer } from "../AuthContext";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import SendChatFileForm from "./Forms/SendChatFileForm";
import ChatFiles from "./ChatFiles";
import { useTranslation } from "react-i18next";

const UploadFileSwal = withReactContent(Swal);

var timestamp = null;

function Chatbox({ type, recipientUser, recipientGroup, recipientDepartment }) {
    const messagesEndRef = createRef();
    let [chat, setChat] = useState(null);
    let [messages, setMessages] = useState(null);
    let [messageText, setMessageText] = useState("");
    // eslint-disable-next-line
    let [lastTimestamp, setLastTimestamp] = useState(null);
    const { t } = useTranslation("common");
    let title = "";
    let description = "";
    let parameter = null;
    if (recipientUser?.name) {
        title = recipientUser.name + (recipientUser.surname ? " " + recipientUser.surname : "");
        description = t("You didn't started chat with {{name}}", { name: title });
        parameter = recipientUser.username;
    } else if (recipientGroup?.name) {
        title = recipientGroup.name;
        description = t("Chat not started for {{name}} group", { name: title });
        parameter = recipientGroup.name;
    } else if (recipientDepartment?.name) {
        title = recipientDepartment.name;
        description = t("Chat not started for {{name}} department", { name: title });
        parameter = recipientDepartment.name;
    }

    useEffect(() => {
        if (parameter) {
            let chatIdApi = process.env.REACT_APP_API_URL + "/chat/getChatInfo/" + type + "/" + parameter;
            axios.get(chatIdApi, {
                withCredentials: true
            }).then((response) => {
                let data = response.data.data;
                setChat(data);
            }).catch((error) => { });
        }
    }, [type, recipientUser, recipientGroup, recipientDepartment, parameter]);

    useEffect(() => {
        if (chat?.ID) {
            let historyApi = process.env.REACT_APP_API_URL + "/chat/getHistory/" + chat.ID;
            axios.get(historyApi, {
                withCredentials: true
            }).then((response) => {
                let data = response.data.data;
                let messages = {};
                for (let message of data.history) {
                    messages[message.message_id] = message;
                }
                setMessages(messages);
                timestamp = data.timestamp;
                setLastTimestamp(timestamp);
            }).catch((error) => { });
        }
    }, [chat])
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView();
    }, [messages, messagesEndRef]);

    useEffect(() => {
        if (chat?.ID) {
            const interval = setInterval(() => {
                let url = new URL(process.env.REACT_APP_API_URL + "/chat/getUpdates/" + chat.ID);
                url.search = new URLSearchParams({
                    timestamp: timestamp
                });
                axios.get(url, {
                    withCredentials: true
                }).then((response) => {
                    let data = response.data.data;
                    if (data.messages.length > 0) {
                        for (let message of data.messages) {
                            messages[message.message_id] = message;
                        }
                        setMessages(messages);
                        setLastTimestamp(timestamp);
                    }
                    timestamp = data.timestamp;
                }).catch((error) => { });
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [chat, messages, recipientUser?.username]);

    function initializeChat() {
        let initializeApi = process.env.REACT_APP_API_URL + "/chat/new/" + type + "/" + parameter;
        axios.get(initializeApi, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            setChat(data);
        }).catch((error) => { });
    }

    function sendMessage() {
        if (!messageText) {
            return;
        }
        let sendMessageApi = process.env.REACT_APP_API_URL + "/chat/sendMessage/" + chat.ID;
        axios.post(sendMessageApi, {
            message: messageText
        }, {
            withCredentials: true
        }).then((response) => {
            let data = response.data.data;
            messages[data.message.message_id] = data.message;
            setMessages(messages);
            setMessageText("");
        }).catch((error) => { });
    }


    function sendFiles() {
        UploadFileSwal.fire({
            title: t("Select files to upload"),
            html: <SendChatFileForm chat={chat} onSuccess={(message) => {
                messages[message.message_id] = message;
                setMessages([...messages]);
            }} />,
            showConfirmButton: false,
            showCloseButton: true,
            preConfirm: function () {

            }
        })
    }

    let photoUrl = recipientUser?.profile_photo ?? "/assets/images/icons/people-user-icon.svg";
    return <AuthConsumer>
        {({ user }) => (
            <>
                {!chat ?
                    <div className="d-flex justify-content-center align-items-center mt-3" style={{
                        height: "calc(100vh - 300px)",
                        maxHeight: "calc(var(--vh, 1vh) * 100 - 400px)",
                        overflow: "auto"
                    }}>
                        <div className="d-flex flex-column">
                            <span className="fs-4 text-light my-4 text-center">
                                {description}
                            </span>
                            <button type="button" className="btn bg-secondary text-white" onClick={() => initializeChat()}>
                                <span className="fa fa-comment-dots"></span> {t("Start chat")}
                            </button>
                        </div>
                    </div> : (
                        <div className="chat-box mt-3">
                            <div className="chat-box-head">
                                <div className="user">
                                    <div className="avatar">
                                        {
                                            recipientUser ? (
                                                <img src={photoUrl} className="rounded-circle" style={{
                                                    width: "25px"
                                                }} alt={title} />
                                            ) : (
                                                recipientGroup ? (
                                                    <div className="bg-secondary text-light rounded rounded-circle">
                                                        <span className="fa fa-users fs-6 m-2"></span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )
                                            )
                                        }
                                    </div>
                                    <div className="name">{title}</div>
                                </div>
                            </div>
                            <div className="chat-body" style={{
                                height: "calc(100vh - 300px)",
                                maxHeight: "calc(var(--vh, 1vh) * 100 - 400px)",
                                overflow: "auto"
                            }}>
                                {
                                    messages ? Object.values(messages).map((message, index) => {
                                        return <div className={user.username !== message.username ? "incoming" : "outgoing"} key={index}>
                                            <div className="bubble">
                                                <ChatFiles message={message} isReceiver={user.username === message.username} />
                                                <p dangerouslySetInnerHTML={{ __html: message.message.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></p>
                                                <div className="bubble-alt">
                                                    <div className="bubble-time">
                                                        <span>{moment(message.created_at, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY HH:mm:ss")}</span>
                                                    </div>
                                                    <div className="bubble-alt-flex">
                                                        <div className="bubble-alt-name">
                                                            <span>{message.name + (message.surname ? " " + message.surname : "")}</span>
                                                        </div>
                                                        <div className="bubble-alt-check read">
                                                            {
                                                                message.username === user.username ? (
                                                                    message.is_read ?
                                                                        <svg className="read-icon" xmlns="http://www.w3.org/2000/svg" width="44.377" height="24.366" viewBox="0 0 44.377 24.366">
                                                                            <path id="Path_1901" data-name="Path 1901" d="M22.489,21.75l2.862,2.862,17.16-17.16,2.866,2.866L25.351,30.345l-12.9-12.9,2.866-2.866,4.307,4.307,2.864,2.862Zm0-5.732L32.531,5.979l2.858,2.858L25.351,18.876Zm-5.73,11.462L13.9,30.345,1,17.445l2.866-2.866L6.73,17.443l0,0L16.763,27.481Z" transform="translate(-1 -5.979)" fill="#e2e2e2"></path>
                                                                        </svg> : <svg className="not-read-icon" xmlns="http://www.w3.org/2000/svg" width="44.377" height="24.366" viewBox="0 0 44.377 24.366">
                                                                            <path id="Path_1901" data-name="Path 1901" d="M22.489,21.75l2.862,2.862,17.16-17.16,2.866,2.866L25.351,30.345l-12.9-12.9,2.866-2.866,4.307,4.307,2.864,2.862Zm0-5.732L32.531,5.979l2.858,2.858L25.351,18.876Zm-5.73,11.462L13.9,30.345,1,17.445l2.866-2.866L6.73,17.443l0,0L16.763,27.481Z" transform="translate(-1 -5.979)" fill="#e2e2e2"></path>
                                                                        </svg>
                                                                ) : ""
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                    }) : ""
                                }
                                <div ref={messagesEndRef} />
                            </div>
                            <div className="foot">
                                <button className={"text-light foot-file-btn"} type="button" onClick={() => sendFiles()}>
                                    <span className="fa fa-paperclip"></span>
                                </button>
                                <textarea className="msg" placeholder={t("Type a message...")} onChange={(e) => { setMessageText(e.target.value) }} value={messageText}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" || e.keyCode === 13) {
                                            e.preventDefault();
                                            sendMessage();
                                        }
                                    }}
                                ></textarea>
                                <button className={"send-button text-light " + (
                                    messageText === "" ? "bg-secondary" : "bg-primary"
                                )} type="submit" onClick={() => sendMessage()} disabled={messageText === ""}>
                                    <span className="fa fa-paper-plane"></span>
                                </button>
                            </div>
                        </div>
                    )
                }
            </>
        )}
    </AuthConsumer>
}

export default Chatbox;