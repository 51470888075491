import { Component } from "react";
import axios from "axios";
import { AuthConsumer } from "../AuthContext";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            username: "",
            password: "",
            form_id: "",
            form_build_id: "",
            form_token: ""
        }
    }

    componentDidMount() {
        this.getCsrf();
    }

    getCsrf() {
        axios.get(process.env.REACT_APP_API_URL + "/login/getCsrfForLoginForm", { withCredentials: true })
            .then((response) => {
                this.setState({
                    form_id: response.data.data.form_id,
                    form_build_id: response.data.data.form_build_id,
                    form_token: response.data.data.form_token,
                });
            })
            .catch(function (error) {
            });
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        for (let index in {
            username: this.state.username,
            password: this.state.password,
            form_id: this.state.form_id,
            form_build_id: this.state.form_build_id,
            form_token: this.state.form_token
        }) {
            formData.append(index, this.state[index]);
        }
        formData.append("remember-me", true);
        axios.post(process.env.REACT_APP_API_URL + "/login/submitLoginForm", formData, { withCredentials: true })
            .then((response) => {
                if (response.data.data) {
                    setTimeout(() => {
                        window.location.assign("/welcome");
                    }, 100);
                }
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error.response.data
                });
            });
        this.getCsrf();
    }

    render() {
        const { t } = this.props;
        return (
            <AuthConsumer>
                {({ user }) => (
                    <div>
                        {user.logged_in ? (
                            <div>
                                {window.location.assign("/")}
                            </div>
                        ) : (
                            <form className="login-main">
                                <DocumentTitle title={t("Login")}></DocumentTitle>
                                <div className="login-main-logo">
                                    <img src="assets/images/main/logo.svg" alt={process.env.REACT_APP_NAME}/>
                                </div>
                                <div className="login-form">
                                    <div className="login-head-text">
                                        <h1>{t("Welcome")}</h1>
                                        <p>{t("Please Fill In the Information To Log In")}</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.message }}></div>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div>
                                    <div className="login-username">
                                        <input type="email" className="form-control" placeholder={t('Username or E-mail')} onChange={(e) => {
                                            this.setState({ username: e.target.value })
                                        }} />
                                    </div>
                                    <div className="login-password">
                                        <input type="password" className="form-control" placeholder={t('Password')} onChange={(e) => {
                                            this.setState({ password: e.target.value })
                                        }} />
                                    </div>
                                    <div className="login-form-alt">
                                        <div className="login-forgot">
                                            <Link to={"/forgot_password"}>{t('Forgot Password')} ?</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="login-main-button mt-5 text-center">
                                    <button onClick={(e) => this.handleSubmit(e)}>{t("Login")}</button>
                                </div>
                            </form>

                        )}
                    </div>
                )}
            </AuthConsumer>

        );
    }
}

const Login = withTranslation('common')(LoginPage);

export default Login;